/* eslint-disable react-hooks/exhaustive-deps */
import { faChevronCircleLeft } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Checkbox, FormControlLabel, Grid, makeStyles, MenuItem, TextField } from '@material-ui/core';
import ConsultantFooter from 'components/ConsultantFooter/ConsultantFooter';
import { PageContainer } from 'components/PageContainer/PageContainer';
import { useSnackbar } from 'notistack';
import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router';
import { ConsultantService } from 'services/ConsultantService';
import { translate } from 'utils';
import { GENERATE_ROUTE, ROUTES } from 'utils/constants/routes';
import { ErrorName } from 'utils/constants/error.name';
import { DEFAULT_CONSULTANT_ID_ROUTE } from 'utils/constants/routes';
import ConsultantGeneralInfos from 'components/ConsultantForm/ConsultantGeneralInfos';
import ConsultantContactInfos from 'components/ConsultantForm/ConsultantContactInfos';
import { CustomSelect } from 'commons/CustomSelect/CustomSelect';
import { EXPERIENCE_YEARS } from 'utils/constants/experienceYears';
import SectionTitle from 'commons/SectionTitle/SectionTitle';
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import ConsultantCV from 'components/ConsultantForm/ConsultantCV';
import ConsultantSkillsList from 'components/ConsultantForm/ConsultantSkills/ConsultantSkillsList';
import { Skeleton } from '@material-ui/lab';

const useStyles = makeStyles((theme) => ({
  name: {
    fontSize: '28px',
    fontWeight: 'bold',
    color: theme.palette.common.black
  },
  availableTag: {
    backgroundColor: theme.palette.secondary.main,
    padding: '2px 15px',
    verticalAlign: "middle",
    color: theme.palette.common.white
  },
  return: {
    color: theme.palette.primary.main,
    cursor: 'pointer',
    marginBottom: '10px',
    "&:hover":{
      textDecoration: 'underline'
    }
  }
}));



export const ConsultantDetailPage = () => {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const { consultantId } = useParams();
  const [isEditionMode, setIsEditionMode] = useState(true);
  const [consultant, setConsultant] = useState({});
  const [isLoadingConsultant, setIsLoadingConsultant] = useState(true);
  const [skillsTreeStructure, setSkillsTreeStructure] = useState(null);
  const [isLoadingSkills, setIsloadingSkills] = useState(true);
  const [canSave, setCanSave] = useState(true);

  const handleChangeConsultant = (fieldName, value) => {
    const consultantTmp = {...consultant};
    consultantTmp[fieldName] = value;
    setConsultant(consultantTmp);
  }

  const handleChangeConsultantTypologies = selectedTypologies => {
    const consultantTmp = {...consultant, typologyRecids: selectedTypologies.map(selectedTypology => selectedTypology.recid)};
    setConsultant(consultantTmp);
  }

  const getConsultantDetails = () => {
    setIsLoadingConsultant(true);
    ConsultantService.getConsultantDetail(consultantId).then(resp => {
      setConsultant(resp);
    }).catch(errorMessage => {
      if (errorMessage !== ErrorName.ABORT_ERROR) {
        enqueueSnackbar(translate('consultantDetail.error'), {variant: 'error'});
      }
    }).finally(() => {
      setIsLoadingConsultant(false);
    })
  }

  const getConsultantSkills = () => {
    ConsultantService.getConsultantSkills(consultantId).then(resp => {
      setIsloadingSkills(false);
      setSkillsTreeStructure(resp);
    })
      .catch(errorMessage => {
        if (errorMessage !== ErrorName.ABORT_ERROR) {
          setIsloadingSkills(false);
          enqueueSnackbar(translate('consultantDetail.error'), {variant: 'error'});
        }
      })
  }

  const saveConsultant = () => {
    const consultantToSave = {...consultant, skillsTreeStructure: [...skillsTreeStructure]}
    ConsultantService.saveConsultant(consultant.recid, consultantToSave).then(resp => {
      enqueueSnackbar(translate('consultantDetail.saveSuccess'), {variant: 'success'});
      navigate(GENERATE_ROUTE(ROUTES.CONSULTANT, resp.data.recid))
    }).catch(errorMessage => {
      if (errorMessage !== ErrorName.ABORT_ERROR) {
        setIsloadingSkills(false);
        enqueueSnackbar(translate('consultantDetail.saveError'), {variant: 'error'});
      }
    })
  }


  useEffect(() => {
    if(consultantId !== DEFAULT_CONSULTANT_ID_ROUTE){
      getConsultantDetails();
      getConsultantSkills();
    }else{
      setIsloadingSkills(false);
      setIsLoadingConsultant(false);
      setIsEditionMode(false);
    }
  },[consultantId])

  const dateIsPast = date => {
    const now = new Date();
    return new Date(date) < now;
  }

  return (
    <PageContainer>
      <Grid container direction="column" style={{marginBottom: '100px'}}>
        <Grid className={classes.return} item onClick={() => navigate(ROUTES.CONSULTANTS)}>
          <Grid alignItems="center" container spacing={1}>
            <Grid item>
              <FontAwesomeIcon icon={faChevronCircleLeft}/>
            </Grid>
            <Grid item>
              {translate('consultantDetail.return')}
            </Grid>
          </Grid>
        </Grid>
        <Grid item>
          <Grid container direction="column" spacing={4}>
            {!isLoadingConsultant && isEditionMode ?
              <Grid item>
                <Grid alignItems="center" container spacing={3}>
                  <Grid className={classes.name} item>
                    {`${consultant?.firstname ?? ''} ${consultant?.lastname ?? ''}`}
                  </Grid>
                  {(consultant?.available && (!consultant?.availableFrom || dateIsPast(consultant?.availableFrom))) &&
                        <Grid item>
                          <div className={classes.availableTag} >
                            {translate("consultantDetail.availableFromNow")}
                          </div>
                        </Grid>
                  }
                </Grid>
              </Grid>
              :
              <Grid className={classes.name} item>
                {translate('consultantDetail.newConsultant')}
              </Grid>
            }
            <Grid item>
              <Grid container justifyContent='space-between' spacing={3} wrap='nowrap'>
                <Grid item xs={9}>
                  <SectionTitle title="consultantDetail.remember"/>
                  {!isLoadingConsultant ?
                    <Grid container direction='column' spacing={2} wrap='nowrap'>
                      <Grid item>
                        <Grid container justifyContent="space-between" spacing={2} wrap='nowrap'>
                          <Grid item style={{minWidth: '150px'}}>
                            <CustomSelect
                              label={translate("consultantDetail.yearsOfExperience")}
                              onChange={handleChangeConsultant}
                              options={EXPERIENCE_YEARS}
                              parameter="yearsOfExperience"
                              renderMenuItem={option => (
                                <MenuItem key={option.id} value={option.id}>{option.value}</MenuItem>
                              )}
                              value={consultant?.yearsOfExperience}
                            />
                          </Grid>
                          <Grid item style={{padding:0, alignSelf: 'flex-end'}}>
                            <FormControlLabel
                              control={(
                                <Checkbox
                                  checked={consultant?.available}
                                  color="secondary"
                                  onChange={() => handleChangeConsultant('available', !consultant?.available)}
                                />
                              )}
                              label={translate("consultantDetail.available")}
                            />
                          </Grid>
                          <Grid item>
                            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                              <KeyboardDatePicker
                                autoOk
                                format="dd/MM/yyyy"
                                label={translate('consultantDetail.availableFrom')}
                                onChange={date => handleChangeConsultant("availableFrom", date)}
                                style={{width: '80%'}}
                                value={consultant?.availableFrom ?? null}
                              />
                            </MuiPickersUtilsProvider>
                          </Grid>
                          <Grid item style={{padding:0, alignSelf: 'flex-end'}}>
                            <FormControlLabel
                              control={(
                                <Checkbox
                                  checked={consultant?.isPoolConsultingFirm}
                                  color="secondary"
                                  onChange={() => handleChangeConsultant('isPoolConsultingFirm', !consultant?.isPoolConsultingFirm)}
                                />
                              )}
                              label={translate("consultantDetail.isPoolConsultingFirm")}
                            />
                          </Grid>
                        </Grid>
                      </Grid>
                      <Grid className={classes.text} item>
                        <TextField
                          maxRows={8}
                          minRows={8}
                          multiline={true}
                          onChange={e => handleChangeConsultant('rememberText', e.target.value)}
                          style={{width:'100%'}}
                          value={consultant?.rememberText}
                          variant="outlined"
                        />
                      </Grid>
                    </Grid>
                    :
                    <Skeleton height="215px" variant="rect" width="100%" />
                  }
                </Grid>
                <Grid item xs={2}>
                  <ConsultantCV
                    cv={consultant?.cv}
                    isCreation={consultantId === DEFAULT_CONSULTANT_ID_ROUTE}
                    isLoadingConsultant={isLoadingConsultant}
                    refresh={getConsultantDetails}
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid item>
              <Grid container justifyContent='space-between' spacing={2} wrap='nowrap'>
                <Grid item xs={5}>
                  <Grid container direction='column' justifyContent='space-between' spacing={3} wrap='nowrap'>
                    <Grid item>
                      <ConsultantGeneralInfos
                        consultant={consultant}
                        handleChangeConsultant={handleChangeConsultant}
                        handleChangeConsultantTypologies={handleChangeConsultantTypologies}
                        isLoadingConsultant={isLoadingConsultant}
                      />
                    </Grid>
                    <Grid item>
                      <ConsultantContactInfos
                        consultant={consultant}
                        handleChangeConsultant={handleChangeConsultant}
                        isLoadingConsultant={isLoadingConsultant}
                        setCanSave={setCanSave}
                      />
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={5}>
                  <ConsultantSkillsList
                    isCreation={consultantId === DEFAULT_CONSULTANT_ID_ROUTE}
                    isLoadingSkills={isLoadingSkills}
                    setSkillsTreeStructure={setSkillsTreeStructure}
                    skillsTreeStructure={skillsTreeStructure}
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <ConsultantFooter canSave={canSave} handleOnReturn={() => navigate(ROUTES.CONSULTANTS)} handleOnSave={saveConsultant}/>
    </PageContainer>
  )
}