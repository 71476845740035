/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { Grid, IconButton, MenuItem, TextField, useTheme } from '@material-ui/core';
import { translate } from 'utils';
import SectionTitle from 'commons/SectionTitle/SectionTitle';
import { ConsultantService } from 'services/ConsultantService';
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowUpRightFromSquare } from '@fortawesome/pro-solid-svg-icons';
import { CustomSelect } from 'commons/CustomSelect/CustomSelect';
import {AutoCompleteWithValue} from "../../commons/AutoComplete/AutoCompleteWithValue";
import { Skeleton } from '@material-ui/lab';



const ConsultantGeneralInfos = ({
  handleChangeConsultant, consultant, handleChangeConsultantTypologies, isLoadingConsultant
}) => {
  const theme = useTheme();
  const [typologies, setTypologies] = useState([]);
  const [isLoadingTypologies, setIsLoadingTypologies] = useState(true);
  const [civilities, setCivilities] = useState([]);
  const [isLoadingCivilities, setIsLoadingCivilities] = useState(true);

  useEffect(() => {
    ConsultantService.getTypologies().then(resp => {
      setTypologies(resp);
      setIsLoadingTypologies(false);
    });
    ConsultantService.getCivilities().then(resp => {
      setCivilities(resp);
      setIsLoadingCivilities(false);
    });
  }, [])

  const formatLinkedinUrl = linkedinUrl => {
    if(!linkedinUrl)
      return '';
    if(!linkedinUrl.includes('http')){
      return `https://${linkedinUrl}`;
    }
    return linkedinUrl;
  }

  return (
    <Grid container direction="column">
      <SectionTitle title="consultantDetail.generalInfos"/>
      {!isLoadingConsultant ?
        <Grid container direction="column" spacing={3}>
          <Grid item>
            <Grid container spacing={2}>
              <Grid item xs={6}>
                <TextField
                  label={translate("consultantDetail.lastname")}
                  onChange={e => handleChangeConsultant("lastname", e.target.value)}
                  style={{width: '100%'}}
                  value={consultant?.lastname ?? ''}
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  label={translate("consultantDetail.firstname")}
                  onChange={e => handleChangeConsultant("firstname", e.target.value)}
                  style={{width: '100%'}}
                  value={consultant?.firstname ?? ''}
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item>
            <Grid alignItems="flex-end" container spacing={2}>
              <Grid item xs={6}>
                <CustomSelect
                  isLoading={isLoadingCivilities}
                  label={translate("consultantDetail.civility")}
                  onChange={handleChangeConsultant}
                  options={civilities}
                  parameter="civility"
                  renderMenuItem={option => (
                    <MenuItem key={option} value={option}>{translate(`consultantDetail.${option}`)}</MenuItem>
                  )}
                  value={consultant?.civility ?? ''}
                />
              </Grid>

              <Grid item xs={6}>
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                  <KeyboardDatePicker
                    autoOk
                    format="dd/MM/yyyy"
                    label={translate('consultantDetail.dateOfBirth')}
                    maxDate={new Date()}
                    onChange={date => handleChangeConsultant("dateOfBirth", date)}
                    style={{width: '100%'}}
                    value={consultant?.dateOfBirth ?? null}
                  />
                </MuiPickersUtilsProvider>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <AutoCompleteWithValue
              isLoading={isLoadingTypologies}
              isMultiple
              label={translate("consultantDetail.typology")}
              onChange={selectedTypologies => handleChangeConsultantTypologies(selectedTypologies)}
              options={typologies}
              readOnly
              value={typologies.filter(typology => consultant.typologyRecids?.includes(typology.recid)) ?? ''}
              variant="standard"
            />
          </Grid>

          <Grid item xs={12}>
            <Grid alignItems="flex-end" container spacing={2}>
              <Grid item xs={11}>
                <TextField
                  label={translate("consultantDetail.linkedinUrl")}
                  onChange={e => handleChangeConsultant("linkedinUrl", e.target.value)}
                  style={{width: '100%'}}
                  value={formatLinkedinUrl(consultant?.linkedinUrl) ?? ''}
                />
              </Grid>
              <Grid item xs={1}>
                <IconButton
                  disabled={!consultant.linkedinUrl}
                  onClick={() => window.open(formatLinkedinUrl(consultant?.linkedinUrl), '_blank', 'noopener,noreferrer')}
                  style={{padding:'0px'}}
                >
                  <FontAwesomeIcon
                    color={!consultant.linkedinUrl ? theme.palette.text.disabled : theme.palette.secondary.main}
                    icon={faArrowUpRightFromSquare}
                    size="lg"
                  />
                </IconButton>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        :
        <Skeleton height="300px" variant="rect" width="100%" />
      }
    </Grid>
  )
};

export default ConsultantGeneralInfos;