/* eslint-disable react-hooks/exhaustive-deps */
import React, {useEffect, useState} from 'react';
import {Grid} from '@material-ui/core';
import {SkillService} from 'services/SkillService';
import {AutoCompleteWithValue} from 'commons/AutoComplete/AutoCompleteWithValue';
import ConsultantSubcategory from './ConsultantSubcategory';
import {updateSkills} from 'utils/helpers/SkillHelper';
import { useCallback } from 'react';


const ConsultantCategory = ({category, setSkillsTreeStructure, skillsTreeStructure, lastNewSubcategoryRecid, setLastNewSubcategoryRecid }) => {
  const [subcategories, setSubcategories] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    SkillService.getSubcategoriesByCategoryId(category.recid).then(resp => {
      const subcategoriesBeforeSort = [...resp];
      const subcategoriesIdSelected = category.skills?.map(subcat => subcat.recid);
      subcategoriesBeforeSort.forEach(subcat => {
        if (subcategoriesIdSelected?.includes(subcat.recid)) {
          subcat.selected = true;
        }
      });
      setSubcategories([...subcategoriesBeforeSort]);
      setIsLoading(false);
    })
  }, [])

  const handleSubcategoriesPopulate = useCallback(() => {
    const skillsTreeStructureTmp = [...skillsTreeStructure];
    const categoryIndex = skillsTreeStructureTmp.findIndex(s => s.recid === category.recid);
    if (!skillsTreeStructureTmp[categoryIndex].skills || skillsTreeStructureTmp[categoryIndex].skills.length === 0) {
      skillsTreeStructureTmp[categoryIndex].skills = [...subcategories];
    } else if (subcategories && subcategories.length > 0) {
      skillsTreeStructureTmp[categoryIndex].skills = updateSkills(
        skillsTreeStructureTmp[categoryIndex].skills,
        [...subcategories]
      );
    }
    setSkillsTreeStructure(skillsTreeStructureTmp);
  },[subcategories])

  useEffect(() => {
    if (!isLoading) {
      handleSubcategoriesPopulate();
    }
  }, [subcategories, isLoading])

  const selectedSubcategoriesGroupByExistingId = selectedSubcategories => {
    const selectedSubcategoriesWithId = [];
    const selectedSubcategoriesWithoutId = [];
    selectedSubcategories.forEach(selectedSubcategory => {
      if (selectedSubcategory.recid) {
        selectedSubcategoriesWithId.push(selectedSubcategory);
      } else {
        selectedSubcategoriesWithoutId.push(selectedSubcategory);
      }
    })
    return {selectedSubcategoriesWithId, selectedSubcategoriesWithoutId};
  }

  const handleSelectSubcategories = selectedSubcategories => {
    const {
      selectedSubcategoriesWithId,
      selectedSubcategoriesWithoutId
    } = selectedSubcategoriesGroupByExistingId(selectedSubcategories);
    const selectedSubcategoriesId = selectedSubcategoriesWithId.map(selectedSubcategory => selectedSubcategory.recid)
    const subcategoriesTmp = [...subcategories];
    subcategoriesTmp.forEach((subcategoryTmp, index) => {
      subcategoriesTmp.splice(index, 1,
        {...subcategoriesTmp[index], selected: selectedSubcategoriesId.includes(subcategoryTmp.recid)})
    })

    selectedSubcategoriesWithoutId.forEach(selectedSubcategoryWithoutId => {
      const alreadyExistingSubcategoryIndex = subcategoriesTmp.findIndex(subcategory => subcategory.name === selectedSubcategoryWithoutId);
      if(alreadyExistingSubcategoryIndex === -1){
        subcategoriesTmp.push({
          recid: lastNewSubcategoryRecid,
          categoryId: category.recid,
          name: selectedSubcategoryWithoutId.name ?? selectedSubcategoryWithoutId,
          selected: true,
          origin: 'CUSTOM',
          skills: []
        })
        setLastNewSubcategoryRecid(lastNewSubcategoryRecid - 1);
      }else{
        subcategoriesTmp[alreadyExistingSubcategoryIndex].selected = true;
      }
    });

    // Removing all skills of a subcategory if it's unselected. Breaks when categories empty (EXT-217)
    // subcategoriesTmp.filter(subcat => !selectedSubcategories.includes(subcat) && subcat.recid)
    //   .forEach(subcat => subcat.skills
    //     .forEach(skill => skill.selected = false));
    setSubcategories([...subcategoriesTmp?.filter(subcategoryTmp => subcategoryTmp.recid)]);
  }

  return (
    !isLoading &&
    <Grid item>
      <Grid container direction="column">
        <Grid item>
          <AutoCompleteWithValue
            isFree
            isLoading={isLoading}
            isMultiple
            onChange={selectedSubcategories => handleSelectSubcategories(selectedSubcategories)}
            options={subcategories}
            value={skillsTreeStructure.filter(cat => cat.recid === category.recid)[0]?.skills?.filter(
              subcategory => subcategory.selected)}
            variant="outlined"
          />
        </Grid>
        {skillsTreeStructure.filter(cat => cat.recid === category.recid)[0]?.skills?.filter(
          subcategory => subcategory.selected).map(subcategory =>
          <Grid item>
            <ConsultantSubcategory
              category={category}
              setSkillsTreeStructure={setSkillsTreeStructure}
              skillsTreeStructure={skillsTreeStructure}
              subcategory={subcategory}
            />
          </Grid>
        )}
      </Grid>
    </Grid>
  )
};

export default ConsultantCategory;