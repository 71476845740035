import React from 'react';
import { Grid, makeStyles } from '@material-ui/core';
import { translate } from 'utils';

const useStyles = makeStyles((theme) => ({
  title: {
    fontWeight: 'bold',
    color: theme.palette.common.black,
    padding: '10px 0 8px 8px',
    borderLeft: `5px solid ${theme.palette.secondary.main}`,
    marginBottom: '10px'
  }
}));

const SectionTitle = ({ title }) => {
  const classes = useStyles();
  return (
    <Grid className={classes.title} item>
      {translate(title)}
    </Grid>
  )
};

export default SectionTitle;