import React, { createContext, useContext, useState } from 'react';
import { CookieHelper } from 'utils/helpers/CookieHelper';
import {useNavigate} from "react-router-dom";

const UserInfoContext = createContext(null);

export const useUserInfo = () => useContext(UserInfoContext);

export const UserInfoProvider = ({ children }) => {
  const [userInfos, setUserInfos] = useState(null);
  const navigate = useNavigate();

  const connectUser = (user) =>{
    setUserInfos(user);
    if (!CookieHelper.getCookie('token')) {
      CookieHelper.setCookie('token', user.token);
    }
  }

  const disconnectUser = (path) => {
    CookieHelper.deleteCookie('token');
    setUserInfos(null);
    setTimeout(() => {navigate(path)}, 300);
  }

  const updateUserInfo = (key, newValue) => {
    setUserInfos(oldState => {
      if (oldState[key] !== newValue) {
        const newState = { ...oldState }
        newState[key] = newValue
        return newState
      } else {
        return oldState
      }
    })
  }

  return (
    <UserInfoContext.Provider
      value={{userInfos, updateUserInfo, connectUser, disconnectUser}}
    >
      {children}
    </UserInfoContext.Provider>
  );
};