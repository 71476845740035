/* eslint-disable react-hooks/exhaustive-deps */
import { faChevronDown } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Chip, CircularProgress } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import * as PropTypes from 'prop-types';
import React, { useState } from 'react';
import { translate } from 'utils';
import { MOBILE_BREAKPOINT } from 'utils/constants/responsive';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    '& .MuiTextField-root': {
      [theme.breakpoints.down(MOBILE_BREAKPOINT)]: {
        marginTop: theme.spacing(1),
        marginBottom: 0
      }
    }
  },
  inputRoot: {
    '& .MuiAutocomplete-tagSizeSmall': {
      maxWidth: 'calc(40% - 4px)'
    }
  },
  modified: {
    '& fieldset': {
      borderColor: '#31A597',
      borderWidth: '2px'
    }
  }
}));

/**
 * AutoComplete is a multi-select list; selected value(s) is(are) synchronized with the query string
 * @param options list of available options
 * @param label translated label of the field
 * @param isFree is selection of items not in the list possible
 * @param isMultiple is the field accepting multiple selects
 * @param className is used to apply style
 * @param isTranslatingOptions function to translate options if needed
 * @param isLoading do the data are loading
 * @param value value to display
 * @param onChange is the function triggered when the value changes
 * @param onChangeTextField is the function triggered when the textfield value changes
 * @param variant the variation of the textfield inside the autocomplete
 * @param error is the is an error
 * @param helperText is the text to display in the helper in case of error
 * @param enableColorChangeOnModification enable the color changement when the value is changed
 */
export const AutoCompleteWithValue = ({
  options, label, isFree, isMultiple, className, isTranslatingOptions, value, onChange, isLoading, onChangeTextField, variant,
  error,
  helperText,
  enableColorChangeOnModification
}) => {
  const classes = useStyles();
  const [isModified, setIsModified] = useState(false);

  const handleOnChangeTextField = (value) => {
    onChangeTextField(value);
    if(enableColorChangeOnModification) setIsModified(true);
  }

  const handleOnChange = (event, value) => {
    onChange(value);
    if(enableColorChangeOnModification) setIsModified(true);
  }

  return (
    <Autocomplete
      classes={{
        inputRoot: classes.inputRoot
      }}
      className={`${className}`}
      filterSelectedOptions
      freeSolo={isFree}
      getOptionLabel={isTranslatingOptions ? (option) => translate(option.name) : (option) => option.name}
      limitTags={isMultiple ? 3 : -1}
      loading={isLoading}
      multiple={isMultiple}
      onChange={handleOnChange}
      options={Array.from(new Set(options.filter((option) => option.name != null && !option.selected).sort()))}
      popupIcon={<FontAwesomeIcon color='black' icon={faChevronDown} size={'2xs'}/>}
      renderInput={(params) => (
        <TextField
          {...params}
          error={error}
          helperText={error ? helperText : ''}
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <>
                {isLoading ?
                  <CircularProgress className='MuiAutocomplete-endAdornment' color='inherit' size={20} />
                  :
                  params.InputProps.endAdornment
                }
              </>
            ),
            className: `${params.InputProps.className} ${isModified && classes.modified}`
          }}
          label={translate(label)}
          margin="normal"
          onChange={e => handleOnChangeTextField(e.target.value)}
          variant={variant}
        />
      )}
      renderTags={(value, getTagProps) =>
        value.map((option, index) => (
          <Chip
            label={option.name ?? option}
            {...getTagProps({ index })}
          />
        ))
      }
      size="medium"
      value={value}
    />
  );
};

AutoCompleteWithValue.propTypes = {
  className: PropTypes.string,
  error: PropTypes.bool,
  helperText: PropTypes.string,
  isFree: PropTypes.bool,
  isMultiple: PropTypes.bool,
  isTranslatingOptions: PropTypes.bool,
  label: PropTypes.string,
  onChangeTextField: PropTypes.func,
  options: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.object, PropTypes.string])),
  variant: PropTypes.string
};

AutoCompleteWithValue.defaultProps = {
  className: '',
  isFree: false,
  isMultiple: false,
  isTranslatingOptions: false,
  onChangeTextField: () => {},
  options: [],
  variant: 'standard'
};