import Typography from '@material-ui/core/Typography';
import React from 'react';

export const generateRow = ({
  name, label, template, width, mobileWidth, isSortable = true, isOnMobile = true, align, bold, displayInfo, infoText, component
}) => ({
  name,
  label: label ?? name,
  isSortable,
  isOnMobile,
  width,
  mobileWidth,
  align,
  bold,
  displayInfo, infoText, component,
  template: (row) => (
    (template && template(row)) || <Typography variant="body2">{row[name]}</Typography>
  )
});