import React from 'react';
import {Grid, makeStyles, useTheme} from '@material-ui/core';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faDeleteLeft, faFloppyDisk} from '@fortawesome/pro-solid-svg-icons';
import {translate} from 'utils';

const useStyles = makeStyles((theme) => ({
  footer: {
    zIndex: 1000,
    backgroundColor: theme.palette.background.footer,
    position: "fixed",
    left: "0",
    bottom: "0",
    height: "80px",
    width: "100%",
    boxShadow: '0px -6px 20px rgba(0, 0, 0, 0.25)'
  },
  container: {
    margin: '18px'
  },
  returnButton: {
    cursor: 'pointer',
    padding: '10px 15px',
    border: `2px solid ${theme.palette.primary.main}`,
    borderRadius: '20px',
    fontWeight: 'bold',
    transition: 'background-color 0.3s ease-in-out',
    '&:hover': {
      borderColor: theme.palette.common.white,
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.common.white,
      "& svg": {
        color: theme.palette.common.white
      }
    }
  },
  saveButton: {
    cursor: 'pointer',
    margin: '0px 40px',
    padding: '10px 15px',
    border: `2px solid ${theme.palette.primary.main}`,
    borderRadius: '20px',
    fontWeight: 'bold',
    transition: 'background-color 0.3s ease-in-out',
    '&:hover': {
      borderColor: theme.palette.common.white,
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.common.white,
      "& svg": {
        color: theme.palette.common.white
      }
    }
  },
  saveButtonDisabled: {
    margin: '0px 40px',
    padding: '10px 15px',
    borderRadius: '20px',
    fontWeight: 'bold',
    background: theme.palette.text.disabledLight,
    color: theme.palette.text.disabledDark,
    "& svg": {
      color: theme.palette.text.disabledDark
    }
  }
}));

const ConsultantFooter = ({handleOnReturn, handleOnSave, canSave = true}) => {
  const classes = useStyles();
  const theme = useTheme();

  const save = () => {
    if(canSave) handleOnSave();
  }

  return (
    <div className={classes.footer}>
      <Grid>
        <Grid className={classes.container} container justifyContent="space-between">
          <Grid className={classes.returnButton} item onClick={handleOnReturn}>
            <Grid container spacing={1}>
              <Grid item>
                <FontAwesomeIcon color={theme.palette.secondary.main} icon={faDeleteLeft} size="xs"/>
              </Grid>
              <Grid item>
                {translate('consultantFooter.return')}
              </Grid>
            </Grid>
          </Grid>
          <Grid className={canSave ? classes.saveButton : classes.saveButtonDisabled} item onClick={save}>
            <Grid container spacing={1}>
              <Grid item>
                <FontAwesomeIcon color={theme.palette.primary.main} icon={faFloppyDisk} size="lg"/>
              </Grid>
              <Grid item>
                {translate('consultantFooter.save')}
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </div>
  )
};

export default ConsultantFooter;