/* eslint-disable react/forbid-prop-types */
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { CustomTooltip } from 'commons/Tooltip/CustomTooltip';
import { ConditionalWrapper } from 'hook/ConditionalWrapper';
import * as PropTypes from 'prop-types';
import React from 'react';

const CustomIcon = ({
  icon, tooltip = '', size, color, ...props
}) => (
  <ConditionalWrapper
    condition={tooltip}
    wrapper={(childrenNodes) => (
      <CustomTooltip title={tooltip}>
        <span>
          {childrenNodes}
        </span>
      </CustomTooltip>
    )}
  >
    <FontAwesomeIcon color={color} icon={icon} size={size} {...props} />
  </ConditionalWrapper>
);

CustomIcon.propTypes = {
  icon: PropTypes.object.isRequired,
  size: PropTypes.string,
  tooltip: PropTypes.string
};

CustomIcon.defaultProps = {
  size: undefined,
  tooltip: ''
};

export default CustomIcon;