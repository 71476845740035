/* eslint-disable react-hooks/exhaustive-deps */
import { faRightFromBracket } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {Grid, makeStyles, useTheme} from '@material-ui/core';
import ConsultantFooter from 'components/ConsultantFooter/ConsultantFooter';
import { PageContainer } from 'components/PageContainer/PageContainer';
import React, {useState} from 'react';
import { useNavigate } from 'react-router';
import { ROUTES } from 'utils/constants/routes';
import {useUserInfo} from 'hook/UseUserInfo';
import { Button } from '@material-ui/core';
import {translate} from "utils";
import {PasswordChangeForm} from "components/UserPageForm/PasswordChangeForm";
import {UserService} from "services";
import {useSnackbar} from "notistack";

const useStyles = makeStyles((theme) => ({
  title: {
    fontSize: '28px',
    fontWeight: 'bold',
    color: theme.palette.primary.main
  },
  name: {
    fontSize: '28px',
    fontWeight: 'bold',
    color: theme.palette.common.black
  },
  return: {
    color: theme.palette.primary.main,
    cursor: 'pointer',
    marginBottom: '10px',
    "&:hover":{
      textDecoration: 'underline'
    }
  },
  disconnectButton: {
    cursor: 'pointer',
    padding: '10px 15px',
    border: `2px solid ${theme.palette.primary.main}`,
    borderRadius: '20px',
    fontWeight: 'bold',
    transition: 'background-color 0.3s ease-in-out',
    '&:hover': {
      borderColor: theme.palette.common.white,
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.common.white,
      "& svg": {
        color: theme.palette.common.white
      }
    }
  }
}));

export const UserPage = () => {
  const classes = useStyles();
  const theme = useTheme();
  const { enqueueSnackbar } = useSnackbar();
  const {disconnectUser, userInfos, updateUserInfo} = useUserInfo();
  const [updatePasswordView, setUpdatePasswordView] = useState({});
  const navigate = useNavigate();

  const handleChangeUser = (fieldName, value) => {
    const updatePasswordViewTemp = {...updatePasswordView};
    updatePasswordViewTemp[fieldName] = value;
    setUpdatePasswordView(updatePasswordViewTemp);
  }

  const handleDisconnection = () => {
    disconnectUser(ROUTES.CONNECTION);
  }

  const handleOnSave = () => {
    const tempUpdatePasswordView = ({
      userRecId: userInfos.id,
      ...updatePasswordView
    });
    setUpdatePasswordView(tempUpdatePasswordView);
    UserService.changePassword(tempUpdatePasswordView).then((resp) => {
      if (resp.data && resp.data.length > 0) {
        resp.data.forEach((key) => {
          enqueueSnackbar(translate(key), {variant: 'error'});
        });
      } else {
        setUpdatePasswordView({});
        enqueueSnackbar(translate("passwordChangeForm.passwordChangeSuccessful"), {variant: 'success'});
        updateUserInfo("hasChangedPassword", true);
        handleDisconnection();
      }
    });
  }

  return (
    <>
      <PageContainer>
        <Grid item>
          <Grid container justifyContent="space-between">
            <Grid className={classes.title} item>
              {translate('userPage.title')}
            </Grid>
            <Grid item>
              <Button
                className={classes.disconnectButton}
                onClick={handleDisconnection}
                startIcon={
                  <FontAwesomeIcon color={theme.palette.primary.main} icon={faRightFromBracket} size="lg"/>
                }
                variant="outlined">
                {translate('userPage.disconnect')}
              </Button>
            </Grid>
          </Grid>
          <PasswordChangeForm
            handleChangeUser={handleChangeUser}
            updatePasswordView={updatePasswordView}
          />
        </Grid>
        <ConsultantFooter
          handleOnReturn={() => navigate(ROUTES.CONSULTANTS)}
          handleOnSave={() => handleOnSave()}
        />
      </PageContainer>
    </>
  )
}