import * as React from 'react';
import { ErrorPage } from 'pages';

export class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false, error: {} };
  }

  // info must be here to properly override
  // eslint-disable-next-line unused-imports/no-unused-vars
  componentDidCatch(error, info) {
    // Display fallback UI
    this.setState({ hasError: true, error: error });
    //UserService.postBugReport({ name: error.name, stack: error.stack, code: error.code });
    console.log({ name: error.name, stack: error.stack, code: error.code });
  }

  render() {
    const { hasError, error } = this.state;
    const { children } = this.props;
    if (hasError) {
      return (
        <ErrorPage errorMessage={ error?.message } />
      );
    }
    return children;
  }
}