import React, { useCallback, useState } from 'react';
import { getAssetUrl } from 'utils/helpers/AssetsHelper';
import { Divider, Fab, Grid, makeStyles, Tab, Tabs, useTheme } from '@material-ui/core';
import { translate } from 'utils';
import { LABEL_CONSULTANTS, LABEL_DATAFARI } from 'utils/constants/tabs';
import { ROUTES } from 'utils/constants/routes';
import CustomIcon from 'commons/Icon/CustomIcon';
import { useNavigate } from 'react-router';
import { useUserInfo } from 'hook/UseUserInfo';
import { extractInitials } from 'utils/processing/string';
import { faArrowUpRightFromSquare, faGraduationCap } from '@fortawesome/pro-solid-svg-icons';

const useStyles = makeStyles((theme) => ({
  header: {
    boxShadow: '0px 0px 8px rgba(0,0,0,.6)'
  },
  container: {
    minHeight: '100px',
    padding: '15px 25px',
    textAlign: 'center'
  },
  applicationName: {
    fontSize: '26px',
    fontWeight: 'bold'
  },
  applicationName1: {
    color: theme.palette.secondary.main
  },
  applicationName2: {
    color: theme.palette.primary.main
  },
  tabs:{
    height: '100%'
  },
  tab: {
    minWidth: '15em',
    fontSize: '112%',
    "&.Mui-selected": {
      "& svg": {
        color: `${theme.palette.secondary.main} !important`
      }
    },
    '&:hover': {
      "& svg": {
        color: `${theme.palette.secondary.main} !important`
      }
    }
  },
  userTab: {
    "&.Mui-selected": {
      "& button": {
        backgroundColor: theme.palette.secondary.main
      }
    },
    '&:hover': {
      "& button": {
        backgroundColor: `${theme.palette.secondary.main} !important`
      }
    }
  },
  datafariTab:{
    background: '/assets/images/Oval1.png',
    '&:hover': {
      "& svg": {
        color: `${theme.palette.secondary.main} !important`
      },
      '& #datafariBg': {
        background: `linear-gradient(to right, ${theme.palette.secondary.main}, ${theme.palette.secondary.dark})`,
        opacity: '0.2',
        width: '90%',
        borderTopLeftRadius: '45px',
        borderBottomLeftRadius: '95px',
        transition: 'width 0.3s ease-in-out'
      }
    }
  },
  datafariBg: {
    position: 'absolute',
    top: '0px',
    right: '0px',
    width: '85%',
    height: '100%',
    backgroundColor: 'rgba(0,0,0,.1)',
    zIndex: -1,
    borderTopLeftRadius: '45px',
    borderBottomLeftRadius: '95px',
    transition: 'width 0.3s ease-in-out'
  },
  background: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    flexShrink: 0,
    height: 67,
    padding: 5
  },
  profileButton: {
    position: 'relative',
    flexShrink: 0,
    height: 50,
    width: 50,
    maxWidth: 50,
    marginRight: theme.spacing(2),
    color: theme.palette.common.white,
    backgroundColor: theme.palette.primary.main,
    borderRadius: '50%',
    boxShadow: 'none'
  },
  userName: {
    fontWeight: 'bold'
  },
  accountLabel:{
    textTransform: 'capitalize'
  }
}));


const Header = () => {
  const classes = useStyles();
  const navigate = useNavigate();
  const {userInfos} = useUserInfo();
  const theme = useTheme();
  const [currentTab, setCurrentTab] = useState(0);

  const handleChangeTab = useCallback((_, tab) => setCurrentTab(tab), []);

  return (
    <Grid className={classes.header} container wrap='nowrap'>
      <Grid className={classes.container} item xs={5}>
        <Grid alignItems="center" container spacing={1} wrap='nowrap'>
          <Grid item>
            <img alt="logo" className={classes.imgTitle} src={getAssetUrl("/assets/images/logo.png")} />
          </Grid>
          <Grid className={classes.applicationName} item xs={true}>
            <Grid container spacing={1} wrap="nowrap">
              <Grid className={classes.applicationName1} item>
                {translate('header.applicationName1')}
              </Grid>
              <Grid className={classes.applicationName2} item>
                {translate('header.applicationName2')}
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>

      <Grid item xs={true}>
        <Tabs
          centered
          classes={{
            root: classes.tabs,
            flexContainer: classes.tabs
          }}
          onChange={handleChangeTab}
          sx={4}
          TabIndicatorProps={{
            style: {
              backgroundColor: theme.palette.secondary.main
            }
          }}
          textColor="primary"
          value={currentTab}
          variant="fullWidth"
        >
          [
          <Tab
            classes={{
              root: classes.tab
            }}
            icon={<CustomIcon icon={faGraduationCap} size="2x" style={{color: theme.palette.primary.main}} />}
            key={LABEL_CONSULTANTS}
            label={<div style={{fontWeight:"bold"}}>{LABEL_CONSULTANTS}</div>}
            onClick={() => {
              if (userInfos?.hasChangedPassword) {
                navigate(ROUTES.CONSULTANTS);
              }
            }}
          />,
          <Divider orientation="vertical" variant="middle" />,
          <Tab
            classes={{
              root: classes.userTab
            }}
            key="user"
            label={
              <Grid alignItems="center" container justifyContent="center" spacing={1} wrap='nowrap'>
                <Grid item>
                  <Grid alignItems="flex-end" className={classes.accountLabel} container direction="column">
                    <Grid className={classes.userName} item>{`${userInfos?.firstname} ${userInfos?.lastname}`}</Grid>
                    <Grid item>{translate('header.manageAccount')}</Grid>
                  </Grid>
                </Grid>
                <Grid className={classes.background} item>
                  <Fab aria-label="user" className={classes.profileButton} size="medium">
                    {(userInfos?.firstname && userInfos?.lastname) && extractInitials(`${userInfos?.firstname}_${userInfos?.lastname}`)}
                  </Fab>
                </Grid>
              </Grid>
            }
            onClick={() => {
              navigate(ROUTES.USER)
            }}
          />,
          <Tab
            classes={{
              root: `${classes.tab} ${classes.datafariTab}`
            }}
            icon={<CustomIcon icon={faArrowUpRightFromSquare} size="2x" style={{color: theme.palette.primary.main}} />}
            key={LABEL_DATAFARI}
            label={
              <>
                <div className={classes.datafariBg} id='datafariBg'></div>
                <div style={{ fontWeight: "bold" }}>{LABEL_DATAFARI}</div>
              </>
            }
            onClick={() => {
              setCurrentTab(0);
              window.open('https://bassine.datafari.com', '_blank', 'noopener,noreferrer');
            }}
          />,
        ]
        </Tabs>
      </Grid>
    </Grid>
  );
};

export default Header;