export const EXPERIENCE_YEARS = [
  {
    value: '0-6',
    id: 'ZERO_TO_SIX'
  },
  {
    value: '7-15',
    id: 'SEVEN_TO_FIFTEEN'
  },
  {
    value: '16-24',
    id: 'SIXTEEN_TO_TWENTY_FOUR'
  },
  {
    value: '25+',
    id: 'TWENTY_FIVE_AND_MORE'
  }
]