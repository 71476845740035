/* eslint-disable react-hooks/exhaustive-deps */
import { faCheck, faPen, faPlus, faSearch, faTrash } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, Checkbox, CircularProgress, FormControlLabel, Grid, IconButton, makeStyles, TextField, useTheme } from '@material-ui/core';
import { DialogType } from 'commons/Dialog/CustomDialog';
import { ConsultantsTable } from 'components/ConsultantsTable/ConsultantsTable';
import { PageContainer } from 'components/PageContainer/PageContainer';
import { useDialog } from 'hook/DialogProvider';
import { useSnackbar } from 'notistack';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router';
import { ConsultantService } from 'services/ConsultantService';
import { translate } from 'utils';
import { CIVILITY } from 'utils/constants/civility';
import { ErrorName } from 'utils/constants/error.name';
import { EXPERIENCE_YEARS } from 'utils/constants/experienceYears';
import { GENERATE_ROUTE, ROUTES } from 'utils/constants/routes';
import { generateRow } from 'utils/helpers/RowHelper';

const useStyles = makeStyles((theme) => ({
  title: {
    fontSize: '28px',
    fontWeight: 'bold',
    color: theme.palette.primary.main
  },
  resultTitle: {
    margin: '4px 0px',
    padding: '5px 10px',
    color: theme.palette.text.disabled,
    fontStyle: 'italic',
    fontWeight: '600'
  },
  deleteButton: {
    cursor: 'pointer',
    padding: '10px 15px',
    border: `2px solid ${theme.palette.error.dark}`,
    borderRadius: '20px',
    fontWeight: 'bold',
    transition: 'background-color 0.3s ease-in-out',
    '&:hover':{
      backgroundColor: theme.palette.error.dark,
      color: theme.palette.common.white,
      "& svg": {
        color: `${theme.palette.common.white} !important`
      }
    }
  },
  hidden: {
    visibility: 'hidden'
  },
  searchBar: {
    minWidth: '500px'
  },
  searchBarEndAdornment: {
    color: theme.palette.primary.main
  },
  tableContainer:{
    maxHeight: '30vw',
    overflowX: 'auto',
    overflowY: 'auto',
    '&::-webkit-scrollbar': {
      height: '12px',
      width: '12px'
    },
    '&::-webkit-scrollbar-track': {
      marginTop: '90px',
      borderRadius: '10px',
      backgroundColor: theme.palette.secondary.light
    },
    '&::-webkit-scrollbar-thumb': {
      borderRadius: '10px',
      backgroundColor: theme.palette.secondary.main
    }
  }
}));


const INITIAL_PAGE = 0;
const INITIAL_ROWS_PER_PAGE = 10;

export const ConsultantsPage = () => {
  const classes = useStyles();
  const theme = useTheme();
  const listInnerRef = useRef();
  const { enqueueSnackbar } = useSnackbar();
  const previousController = useRef();
  const navigate = useNavigate();
  const showDialog = useDialog();
  const [consultants, setConsultants] = useState([]);
  const [total, setTotal] = useState(0);
  const [isLoadingConsultants, setIsLoadingConsultants] = useState(false);
  const [selectAll, setSelectedAll] = useState(false);
  const [headers, setHeaders] = useState([]);
  const [selectedConsultants, setSelectedConsultants] = useState([]);
  const [page, setPage] = useState(INITIAL_PAGE);
  const [searchName, setSearchName] = useState();
  const [isLoadingDeleteConsultants, setIsLoadingDeleteConsultants] = useState(false);

  const handleChangeSearchName = value => {
    setSearchName(value);
    if(value.length >= 4){
      updateData([], value);
    }else if(value.length === 0){
      updateData([]);
    }
  }

  const addNewConsultants = (rows, newRows) => {
    return [
      ...rows,
      ...newRows.map(newRow => {
        if(!rows.find(row => row.recid === newRow.recid)){
          return newRow;
        }else{
          return null;
        }
      }).filter(nR => nR !== null)
    ]
  }

  const updateData = useCallback((oldConsultants = consultants, consultantNameToSearch) => {
    setIsLoadingConsultants(true);

    if (previousController.current) {
      previousController.current.abort();
    }
    const controller = new AbortController();
    const { signal } = controller;
    previousController.current = controller;

    const filter = {
      page: page
    };

    if (!filter.page) {
      filter.page = INITIAL_PAGE;
    }
    if (!filter.rowsPerPage) {
      filter.rowsPerPage = INITIAL_ROWS_PER_PAGE;
    }

    if(consultantNameToSearch){
      filter.consultantNameToSearch = consultantNameToSearch;
      filter.page = INITIAL_PAGE;
      filter.rowsPerPage = INITIAL_ROWS_PER_PAGE;
    }

    ConsultantService.getConsultants(filter, signal)
      .then(({data}) => {
        if(oldConsultants.length > 0){
          setConsultants(addNewConsultants(oldConsultants, data.consultantProjections));
        }else{
          setConsultants(data.consultantProjections);
        }
        setTotal(data.total);
        setIsLoadingConsultants(false);
      })
      .catch(errorMessage => {
        if (errorMessage !== ErrorName.ABORT_ERROR) {
          setIsLoadingConsultants(false);
          if (errorMessage === ErrorName.JWT_ERROR) {
            enqueueSnackbar(translate('common.jwtError'), {variant: 'error'});
          } else {
            enqueueSnackbar(translate('consultant.error.load'), {variant: 'error'});
          }
        }
      })
  }, [page, searchName])

  useEffect(() => {
    updateData();
  }, [])

  const handleChangePage = (newPage) => {
    setPage(newPage);
  };

  useEffect(() => {
    updateData(consultants, (searchName && searchName.length >= 4) ? searchName : null);
  }, [page])

  useEffect(() => {
    if(selectedConsultants.length === 0){
      setSelectedAll(false);
    }else if(selectedConsultants.length === consultants.length){
      setSelectedAll(true);
    }
  }, [selectedConsultants])

  const handleSelectConsultant = useCallback(idConsultant => {
    if(idConsultant || idConsultant === 0){
      if(selectedConsultants.includes(idConsultant)){
        const tmpSelectedConsultants = [...selectedConsultants].filter(id => id !== idConsultant);
        setSelectedConsultants(tmpSelectedConsultants);
      }else{
        setSelectedConsultants([...selectedConsultants, idConsultant]);
      }
    }else{
      if(selectAll){
        setSelectedAll(false);
        setSelectedConsultants([]);
      }else{
        setSelectedAll(true);
        setSelectedConsultants(consultants.map(consultant => consultant.recid));
      }
    }
  }, [consultants, selectAll, selectedConsultants])

  const onScroll = () => {
    if (listInnerRef.current) {
      const { scrollTop, scrollHeight, clientHeight } = listInnerRef.current;
      if (scrollTop + clientHeight >= scrollHeight - 1 && !isLoadingConsultants && consultants.length < total) {
        const newPage = page ? page + 1 : 0 + 1;
        setIsLoadingConsultants(true);
        handleChangePage(newPage);
      }
    }
  };

  useEffect(() =>{
    setHeaders(
      [
        generateRow({
          component: <FormControlLabel
            control={(
              <Checkbox
                checked={selectAll}
                color="secondary"
                onChange={() => handleSelectConsultant()}
                style={{
                  backgroundColor: theme.palette.common.white
                }}
              />
            )}
          />,
          width: '2%',
          template: (row) => (
            <FormControlLabel
              control={(
                <Checkbox
                  checked={selectedConsultants.includes(row.recid)}
                  color="secondary"
                  onChange={() => handleSelectConsultant(row.recid)}
                />
              )}
            />
          )
        }),
        generateRow({
          name: 'consultant.lastname',
          width: '10%',
          isSortable: false,
          bold: true,
          template: (row) => (
            <div style={{capitalize: 'capitalizes'}}>
              {row.lastname}
            </div>
          )
        }),
        generateRow({
          name: 'consultant.firstname',
          width: '10%',
          isSortable: false,
          bold: true,
          template: (row) => (
            <div style={{capitalize: 'capitalizes'}}>
              {row.firstname}
            </div>
          )
        }),
        generateRow({
          name: 'consultant.civility',
          width: '10%',
          isSortable: false,
          template: (row) => (
            <div>
              {translate(`consultant.${CIVILITY.find(civility => civility.id ===row.civility)?.text}`)}
            </div>
          )
        }),
        generateRow({
          name: 'consultant.experienceYears',
          width: '5%',
          isSortable: false,
          template: (row) => (
            row.yearsOfExperience && (
              <div>
                {`${translate(EXPERIENCE_YEARS.filter(yearsOfExperience => yearsOfExperience.id === row.yearsOfExperience)[0]?.value)}
              ${translate('consultant.years')}`}
              </div>
            )
          )
        }),
        generateRow({
          name: 'consultant.city',
          width: '5%',
          isSortable: false,
          template: (row) => (
            <div>
              {row.city}
            </div>
          )
        }),
        generateRow({
          name: 'consultant.country',
          width: '5%',
          isSortable: false,
          template: (row) => (
            <div>
              {row.country}
            </div>
          )
        }),
        generateRow({
          name: 'consultant.available',
          width: '2%',
          isSortable: false,
          align: 'center',
          template: (row) => (
            row.available ?
              <FontAwesomeIcon color={theme.palette.secondary.main} icon={faCheck}/>
              :
              <></>
          )
        }),
        generateRow({
          name: 'consultant.availableFrom',
          width: '5%',
          isSortable: false,
          template: (row) => (
            <div>
              {
                (row.availableFrom) ?
                  new Date(row.availableFrom).toLocaleDateString()
                  :
                  (!row.availableFrom && row.available) ?
                    translate('consultant.now')
                    :
                    ''
              }
            </div>
          )
        }),
        generateRow({
          align: 'center',
          name: 'consultant.actions',
          width: '5%',
          isSortable: false,
          template: (row) => (
            <div>
              <IconButton
                onClick={() => {navigate(GENERATE_ROUTE(ROUTES.CONSULTANT, row.recid))}}
                style={{border:`1px solid ${theme.palette.primary.main}`}}
              >
                <FontAwesomeIcon color={theme.palette.secondary.main} icon={faPen} size="xs"/>
              </IconButton>
            </div>
          )
        })
      ]);
  }, [selectedConsultants, consultants, handleSelectConsultant, selectAll])

  const handleOnDeleteConsultants = () => {
    setIsLoadingDeleteConsultants(true);
    ConsultantService.deleteConsultants(selectedConsultants).then(() => {
      enqueueSnackbar(translate('consultant.deleteConsultantsSuccess', {consultantNumber: selectedConsultants.length}), {variant: 'success'});
      setConsultants([]);
      setPage(page === 0 ? null : INITIAL_PAGE);
    }).catch(errorMessage => {
      if (errorMessage !== ErrorName.ABORT_ERROR) {
        setIsLoadingConsultants(false);
        if (errorMessage === ErrorName.JWT_ERROR) {
          enqueueSnackbar(translate('common.jwtError'), {variant: 'error'});
        } else {
          enqueueSnackbar(translate('consultant.deleteConsultantsError', {consultantNumber: selectedConsultants.length}), {variant: 'error'});
        }
      }
    }).finally(() => {
      setIsLoadingDeleteConsultants(false);
      setSelectedConsultants([]);
    })
  }

  return (
    <PageContainer>
      <Grid container direction="column" justifyContent="space-between" spacing={3}>
        <Grid item>
          <Grid container justifyContent="space-between">
            <Grid className={classes.title} item>
              {translate('consultant.title')}
            </Grid>
            <Grid item>
              <Button
                color="primary"
                onClick={() => {
                  navigate(GENERATE_ROUTE(ROUTES.CONSULTANT));
                }}
                startIcon={<FontAwesomeIcon color={theme.palette.common.white} icon={faPlus} size="lg"/>}
                variant="contained"
              >
                {translate('consultant.add')}
              </Button>
            </Grid>
          </Grid>
        </Grid>
        <Grid item>
          <TextField
            className={classes.searchBar}
            InputProps={{
              endAdornment: (
                <FontAwesomeIcon color="primary" icon={faSearch} size="xl"/>
              ),
              className: `${classes.searchBarEndAdornment}`
            }}
            label={translate('consultant.searchBar')}
            onChange={e => handleChangeSearchName(e.target.value)}
            value={searchName}
            variant="standard"
          />
        </Grid>
        <Grid item>
          <Grid container justifyContent="space-between">
            <Grid className={classes.resultTitle} item>
              {`${translate('consultant.results')} ${consultants?.length}/${total}`}
            </Grid>
            <Grid
              className={`${isLoadingDeleteConsultants ? '' : classes.deleteButton} ${selectedConsultants?.length === 0 ? classes.hidden : ''}`}
              item
              onClick={() => {
                showDialog({
                  variant: DialogType.CONFIRMATION,
                  title: 'consultant.confirmationDeleteConsultantsTitle',
                  description: translate('consultant.confirmationDeleteConsultants', {consultantNumber: selectedConsultants.length}),
                  onSubmit: handleOnDeleteConsultants
                });
              }}
            >
              {isLoadingDeleteConsultants ?
                <CircularProgress/>
                :
                <Grid container spacing={1}>
                  <Grid item>
                    <FontAwesomeIcon color={theme.palette.error.dark} icon={faTrash} size="xs"/>
                  </Grid>
                  <Grid item>
                    {`${translate('consultant.delete')} (${selectedConsultants?.length})`}
                  </Grid>
                </Grid>
              }
            </Grid>
          </Grid>
        </Grid>
        <Grid className={classes.tableContainer} innerRef={listInnerRef} item onScroll={onScroll}>
          <ConsultantsTable
            headers={headers}
            isLoading={isLoadingConsultants}
            page={page}
            rowKey="consultant"
            rows={consultants}
          />
        </Grid>
      </Grid>
    </PageContainer>
  )
}