export const updateSkills = (originalSkills = [], newSkills) => {
  let originalSkillsTmp = [...originalSkills];
  const newSkillsTmp = [...newSkills];
  if (originalSkillsTmp.length > newSkillsTmp.length) {
    originalSkillsTmp.filter(skill => !newSkillsTmp.includes(skill)).forEach(skill => skill.selected = false);
  }
  const newSkillToRemoveIndexes = [];
  const originalSkillToRemoveIndexes = []
  originalSkillsTmp.forEach((originalSkill, index) => {
    const newSkillIndex = newSkillsTmp.findIndex(newSkill => (newSkill.recid && newSkill.recid === originalSkill.recid)
      || (newSkill.recid === null && newSkill.name === originalSkill.name));
    if(newSkillIndex !== -1){
      if(originalSkillsTmp[index].selected !== newSkillsTmp[newSkillIndex].selected){
        const newSkill = {...originalSkillsTmp[index], selected: newSkillsTmp[newSkillIndex].selected}
        originalSkillToRemoveIndexes.push(index);
        originalSkillsTmp.push(newSkill);
      }
      newSkillToRemoveIndexes.push(newSkillIndex);
    }
  });

  return [
    ...originalSkillsTmp.filter((_, index) => !originalSkillToRemoveIndexes.includes(index)),
    ...newSkillsTmp.filter((_, index) => !newSkillToRemoveIndexes.includes(index))
  ];
}