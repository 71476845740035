/* eslint-disable react-hooks/exhaustive-deps */
import React, {useEffect, useState} from 'react';
import {Grid, MenuItem, TextField} from '@material-ui/core';
import {translate} from 'utils';
import SectionTitle from 'commons/SectionTitle/SectionTitle';
import {ConsultantService} from 'services/ConsultantService';
import {CustomSelect} from 'commons/CustomSelect/CustomSelect';
import {Skeleton} from '@material-ui/lab';
import {handleMailRegexValidation, handleTelRegexValidation} from 'utils/helpers/FormHelper';


const ConsultantContactInfos = ({ handleChangeConsultant, consultant, isLoadingConsultant, setCanSave }) => {
  const [frenchAreas, setFrenchAreas] = useState([]);
  const [isLoadingFrenchAreas, setIsLoadingFrenchAreas] = useState(true);
  const [countries, setCountries] = useState([{name: {common: 'France'}}]);
  const [isLoadingCountries, setIsLoadingCountries] = useState(true);
  const [errorPhoneNumber, setErrorPhoneNumber] = useState(false);
  const [errorEmail, setErrorEmail] = useState(false);

  const handleChangeConsultantContactInfos = (param, value) => {
    handleChangeConsultant(param, value);
    if (param === 'phoneNumber') {
      setErrorPhoneNumber(handleTelRegexValidation(value));
    } else if (param === 'email') {
      setErrorEmail(handleMailRegexValidation(value));
    }
  }

  const countriesCompare = (country1, country2) => {
    if (country1.name.common < country2.name.common) {
      return -1;
    }
    if (country1.name.common > country2.name.common) {
      return 1;
    }
    return 0;
  }

  useEffect(() => {
    if(errorPhoneNumber || errorEmail){
      setCanSave(false);
    }else{
      setCanSave(true);
    }
  }, [errorPhoneNumber, errorEmail])

  useEffect(() => {
    ConsultantService.getFrenchAreas().then(resp => {
      setFrenchAreas(resp);
      setIsLoadingFrenchAreas(false);
    });
    ConsultantService.getCountries().then(resp => {
      setCountries(resp.filter(country => country.name).sort(countriesCompare));
      setIsLoadingCountries(false);
    });
  }, [])

  const processFrenchArea = frenchArea => {
    const frenchAreaWords = frenchArea.toLowerCase().replaceAll('_', ' ').split(' ');
    for (let i = 0; i < frenchAreaWords.length; i++) {
      frenchAreaWords[i] = frenchAreaWords[i][0].toUpperCase() + frenchAreaWords[i].substr(1);
    }
    frenchArea = frenchAreaWords.join(" ");
    return frenchArea.charAt(0).toUpperCase() + frenchArea.slice(1);
  }

  return (
    <Grid container direction="column">
      <SectionTitle title="consultantDetail.contactInfos"/>
      {!isLoadingConsultant ?
        <Grid container direction="column" spacing={3}>
          <Grid item xs={12}>
            <TextField
              error={errorPhoneNumber}
              label={translate("consultantDetail.phoneNumber")}
              onChange={e => handleChangeConsultantContactInfos("phoneNumber", e.target.value)}
              style={{width: '100%'}}
              value={consultant?.phoneNumber}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              error={errorEmail}
              label={translate("consultantDetail.email")}
              onChange={e => handleChangeConsultantContactInfos("email", e.target.value)}
              style={{width: '100%'}}
              value={consultant?.email}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              label={translate("consultantDetail.city")}
              onChange={e => handleChangeConsultantContactInfos("city", e.target.value)}
              style={{width: '100%'}}
              value={consultant?.city}
            />
          </Grid>
          <Grid item xs={12}>
            <CustomSelect
              isLoading={isLoadingFrenchAreas}
              label={translate("consultantDetail.frenchArea")}
              onChange={handleChangeConsultantContactInfos}
              options={frenchAreas}
              parameter="frenchArea"
              renderMenuItem={option => (
                <MenuItem key={option} value={option}>{processFrenchArea(option)}</MenuItem>
              )}
              value={consultant?.frenchArea ?? ''}
            />
          </Grid>
          <Grid item xs={12}>
            <CustomSelect
              isLoading={isLoadingCountries}
              label={translate("consultantDetail.country")}
              onChange={handleChangeConsultantContactInfos}
              options={countries}
              parameter="country"
              renderMenuItem={option => (
                <MenuItem key={option.name.common} value={option.name.common}>{option.name.common}</MenuItem>
              )}
              value={consultant?.country ?? ''}
            />
          </Grid>
        </Grid> :
        <Skeleton height="350px" variant="rect" width="100%"/>
      }
    </Grid>
  )
};

export default ConsultantContactInfos;