import { faChevronDown, faChevronUp } from "@fortawesome/pro-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { CircularProgress, ClickAwayListener, TextField } from "@material-ui/core"
import { useEffect } from "react"
import { useState } from "react"
import { translate } from "utils"

export const CustomSelect = ({options, isLoading, onChange, label, value, parameter, renderMenuItem}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [isDisabled, setIsDisabled] = useState(false);

  useEffect(() => {
    setIsDisabled(options?.length === 0);
  },[options])

  const handleOnChange = value => {
    if(parameter){
      onChange(parameter, value);
    }else{
      onChange(value);
    }
    setIsOpen(false);
  }

  const handleOpenning = () => {
    if(!isDisabled){
      setIsOpen(!isOpen)
    }
  }

  return (
    <ClickAwayListener onClickAway={() => setIsOpen(false)}>
      <TextField
        disabled={isDisabled}
        label={translate(label)}
        labelId={`select-${parameter}`}
        onChange={e => handleOnChange(e.target.value)}
        onClick={handleOpenning}
        select
        SelectProps={{
          open: isOpen,
          IconComponent: props => (
            isLoading ?
              <CircularProgress size={20}/>
              :
              <FontAwesomeIcon
                icon={isDisabled ? faChevronDown : props.className === 'MuiSelect-icon' ? faChevronDown : faChevronUp}
                onClick={handleOpenning}
                size="md"
                style={{cursor: isDisabled ? 'auto' : 'pointer'}}
              />
          )
        }}
        style={{width: '100%'}}
        value={value ?? ''}
      >
        {options.map(option => (
          renderMenuItem(option)
        ))}
      </TextField>
    </ClickAwayListener>
  )
}