import React, {useState} from 'react';
import {Button, CircularProgress, Grid, IconButton, makeStyles, useTheme} from '@material-ui/core';
import SectionTitle from 'commons/SectionTitle/SectionTitle';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faDownload, faFileUser, faPlus, faTrash, faUpload} from '@fortawesome/pro-solid-svg-icons';
import {translate} from 'utils';
import {useSnackbar} from 'notistack';
import {useDialog} from 'hook/DialogProvider';
import {DialogType} from 'commons/Dialog/CustomDialog';
import Dropzone from 'react-dropzone'
import {useParams} from 'react-router';
import {CVService} from 'services/CVService';
import { Skeleton } from '@material-ui/lab';

const useStyles = makeStyles((theme) => ({
  cvContainer: {
    position: 'relative',
    borderRadius: '8px',
    border: '1px solid black',
    width: '260px',
    padding: '20px 40px'
  },
  cvContainerEmpty: {
    borderRadius: '8px',
    border: `1px dashed ${theme.palette.secondary.main}`,
    padding: '20px 40px',
    width: '260px',
    cursor: 'pointer',
    height: '215px',
    textAlign: 'center'
  },
  browseButton: {
    fontWeight: 'bold',
    color: theme.palette.primary.main
  },
  delete: {
    position: 'absolute',
    top: '1%',
    right: '1%',
    color: theme.palette.primary.main
  },
  container:{
    height:'100%'
  }
}));

const ConsultantCV = ({
  cv, refresh, isLoadingConsultant, isCreation
}) => {
  const classes = useStyles();
  const theme = useTheme();
  const { enqueueSnackbar } = useSnackbar();
  const showDialog = useDialog();
  const [isLoadingDeleteCV, setIsloadingDeleteCV] = useState(false);
  const [isLoadingAddCV, setIsloadingAddCV] = useState(false);
  const { consultantId } = useParams();
  const [isDragActive, setIsDragActive] = useState(false);

  const getFileExtension = () => {
    const cvParts = cv.fileName.split('.');
    return cvParts[cvParts.length-1];
  }

  const getPDFWithBase64 = (base64Content) => (
    `data:application/${getFileExtension()};base64,${base64Content}`
  );

  const downloadCV = () => {
    const tempLink = document.createElement('a');
    tempLink.setAttribute('href', getPDFWithBase64(cv.file));
    tempLink.setAttribute('download', cv.fileName);
    tempLink.click();
  }

  const deleteCV = () => {
    setIsloadingDeleteCV(true);
    CVService.deleteCV(cv.recid).then(() => {
      enqueueSnackbar(translate('consultantDetail.validDelete'), {variant: 'success'});
      refresh();
    }).catch(() => {
      enqueueSnackbar(translate('consultantDetail.errorDelete'), {variant: 'error'});
    }).finally(() => {
      setIsloadingDeleteCV(false);
    })
  }

  const addCV = files => {
    if(!files) return

    setIsDragActive(false);
    setIsloadingAddCV(true);

    const formData = new FormData();
    formData.append("file", files[0]);
    CVService.addCV(consultantId, formData).then(() => {
      enqueueSnackbar(translate('consultantDetail.validAdd'), {variant: 'success'});
      refresh();
    }).catch(() => {
      enqueueSnackbar(translate('consultantDetail.errorAdd'), {variant: 'error'});
    }).finally(() => {
      setIsloadingAddCV(false);
    })
  }

  const formatFileName = () => {
    if(cv.fileName.length > 20){
      return `${cv.fileName.split('.')[0].substring(0,20)}...${getFileExtension()}`;
    }
    return cv.fileName;
  }

  return (
    <Grid className={classes.container} container direction='column' justifyContent={isCreation ? '' : 'space-between'}>
      <SectionTitle title="consultantDetail.cv"/>
      {!isLoadingConsultant ?
        (isCreation) ?
          <div style={{paddingTop: '50px'}}>
            {translate('consultantDetail.cvDuringCreation')}
          </div>
          :
          (cv && cv.file) ?
            <Grid item>
              <Grid
                alignItems='center'
                className={classes.cvContainer}
                container
                direction='column'
                justifyContent='center'
                spacing={1}
                wrap="nowrap"
              >
                <IconButton
                  className={classes.delete}
                  disabled={isLoadingDeleteCV}
                  onClick={() => {
                    showDialog({
                      variant: DialogType.CONFIRMATION,
                      title: 'consultantDetail.confirmationDeleteCVTitle',
                      description: 'consultantDetail.confirmationDeleteCV',
                      onSubmit: deleteCV
                    });
                  }}
                >
                  {isLoadingDeleteCV ?
                    <CircularProgress size={25}/>
                    :
                    <FontAwesomeIcon icon={faTrash}/>
                  }
                </IconButton>
                <Grid item>
                  <FontAwesomeIcon color={theme.palette.secondary.main} icon={faFileUser} size="5x"/>
                </Grid>
                <Grid item>
                  {formatFileName()}
                </Grid>
                <Grid item>
                  {`${translate('consultantDetail.import')} ${new Date(cv.importDate).toLocaleDateString()}`}
                </Grid>
                <Grid item>
                  <Button
                    color="primary"
                    onClick={downloadCV}
                    startIcon={<FontAwesomeIcon color={theme.palette.primary.main} icon={faDownload} size="lg"/>}
                    variant="outlined"
                  >
                    {translate('consultantDetail.downloadCv')}
                  </Button>
                </Grid>
              </Grid>
            </Grid>
            :
            <>
              <Grid item>
                <Dropzone
                  multiple={false}
                  onDragEnter={() => setIsDragActive(true)}
                  onDragLeave={() => setIsDragActive(false)}
                  onDrop={acceptedFile => addCV(acceptedFile)}
                >
                  {({getRootProps, getInputProps}) => (
                    <section>
                      <div {...getRootProps()}>
                        <input {...getInputProps()} />
                        {isLoadingAddCV ?
                          <div className={classes.cvContainerEmpty}>
                            <CircularProgress size={100}/>
                          </div>
                          :
                          isDragActive ?
                            <div className={classes.cvContainerEmpty}>
                              <FontAwesomeIcon color={theme.palette.primary.main} icon={faPlus} size="5x"/>
                            </div>
                            :
                            <Grid alignItems='center'
                              className={classes.cvContainerEmpty} container direction='column' justifyContent='center' spacing={1} wrap="nowrap">
                              <Grid item>
                                <FontAwesomeIcon color={theme.palette.primary.main} icon={faUpload} size="5x"/>
                              </Grid>
                              <Grid item>
                                {translate('consultantDetail.dragNDrop')}
                              </Grid>
                              <Grid item>
                                {translate('consultantDetail.or')}
                              </Grid>
                              <Grid className={classes.browseButton} item>
                                {translate('consultantDetail.browse')}
                              </Grid>
                            </Grid>
                        }
                      </div>
                    </section>
                  )}
                </Dropzone>
              </Grid>
            </>
        :
        <Skeleton height="215px" variant="rect" width="260px" />
      }
    </Grid>
  )
};

export default ConsultantCV;