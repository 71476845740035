export const CIVILITY = [
  {
    text: 'mister',
    id: 'MONSIEUR'
  },
  {
    text: 'madam',
    id: 'MADAME'
  },
  {
    text: 'miss',
    id: 'MADEMOISELLE'
  },
  {
    text: 'other',
    id: 'AUTRE'
  }
]