/* eslint-disable react-hooks/exhaustive-deps */
import React, {useEffect, useState} from 'react';
import {
  Button,
  Grid,
  IconButton,
  InputAdornment,
  makeStyles,
  TextField,
  useMediaQuery,
  useTheme
} from '@material-ui/core';
import {getAssetUrl} from 'utils/helpers/AssetsHelper';
import {translate} from 'utils';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faArrowsToCircle, faCheckToSlot, faEye, faEyeSlash} from '@fortawesome/pro-solid-svg-icons';
import {useUserInfo} from 'hook/UseUserInfo';
import {useNavigate} from 'react-router';
import {ROUTES} from 'utils/constants/routes';
import {faUsers} from '@fortawesome/pro-regular-svg-icons';
import {AuthService, UserService} from 'services';
import { CookieHelper } from 'utils/helpers/CookieHelper';
import { useSnackbar } from 'notistack';

const useStyles = makeStyles((theme) => ({
  connectionContainer: {
    marginTop: '30px',
    width: '50%'
  },
  imgTitle: {
    height: '118px'
  },
  connectionFormContainer: {
    marginTop: '10px',
    color: theme.palette.primary.main
  },
  connectionForm: {
    padding: '0 20px',
    maxWidth: '100%',
    margin: '10px 0'
  },
  hello: {
    fontSize: '32px',
    fontWeight: 'bold'
  },
  description: {
    fontSize: '18px'
  },
  connectionInput: {
    minWidth: '600px'
  },
  connectionButton: {
    minWidth: '200px !important',
    color: `${theme.palette.common.white} !important`,
    backgroundColor: `${theme.palette.primary.main} !important`
  },
  imgOval: {
    position: 'absolute',
    top: '0px',
    right: '0px'
  },
  imgTalents: {
    position: 'absolute',
    top: '0px',
    right: '0px',
    width: '45%'
  },
  applicationName: {
    fontSize: '40px',
    fontWeight: 'bold'
  },
  applicationName1: {
    color: theme.palette.secondary.main
  },
  applicationName2: {
    color: theme.palette.primary.main
  },
  iconContainer: {
    marginTop: '25px',
    textAlign: 'center'
  }
}));

export const ConnectionPage = () => {
  const classes = useStyles();
  const theme = useTheme();
  const {connectUser} = useUserInfo();
  const navigate = useNavigate();
  const mediaQuery = useMediaQuery('(max-width:1350px)');
  const { enqueueSnackbar } = useSnackbar();
  const [login, setLogin] = useState();
  const [password, setPassword] = useState();
  const [showPassword, setShowPassword] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  const handleOnConnect = () => {
    if(login === '' || password === ''){
      enqueueSnackbar(translate('connection.missingCredentials'), {variant: 'error'});
      return;
    }
    const loginRequest = {
      login: login,
      password: password
    };
    AuthService.signIn(loginRequest).then(userInfo => {
      const userConnected = {
        id: userInfo.id,
        hasChangedPassword: userInfo.hasChangedPassword,
        lastname: userInfo.lastname,
        firstname: userInfo.firstname,
        status: userInfo.isActive,
        token: userInfo.token
      }
      connectUser(userConnected);
      userInfo.hasChangedPassword ? navigate(ROUTES.CONSULTANTS) : navigate(ROUTES.USER);
    }).catch(() => {
      enqueueSnackbar(translate('connection.errorConnection'), {variant: 'error'});
    });
  }

  useEffect(() => {
    if (CookieHelper.getCookie('token')) {
      UserService.getUserInfo().then(userInfo => {
        const userConnected = {
          id: userInfo.id,
          hasChangedPassword: userInfo.hasChangedPassword,
          lastname: userInfo.lastname,
          firstname: userInfo.firstname,
          status: userInfo.isActive
        }
        setIsLoading(false);
        connectUser(userConnected);
        userConnected.hasChangedPassword ? navigate(ROUTES.CONSULTANTS) : navigate(ROUTES.USER);
      }).catch(() => {
        setIsLoading(false);
      });
    } else {
      setIsLoading(false);
    }
  }, [])

  return (
    !isLoading &&
    (<>
      {!mediaQuery && (
        <>
          <img alt="logo" className={classes.imgOval} src={getAssetUrl("/assets/images/Oval1.png")}/>
          <img alt="logo" className={classes.imgTalents} src={getAssetUrl("/assets/images/ImageTalents.png")}/>
        </>
      )}
      <Grid alignItems='center' className={classes.connectionContainer} container direction="column" spacing={3}>
        <Grid item>
          <Grid alignItems='center' container direction="column" spacing={1}>
            <Grid item>
              <img alt="logo" className={classes.imgTitle} src={getAssetUrl("/assets/images/logo.png")}/>
            </Grid>
            <Grid className={classes.applicationName} item>
              <Grid container spacing={1}>
                <Grid className={classes.applicationName1} item>
                  {translate('header.applicationName1')}
                </Grid>
                <Grid className={classes.applicationName2} item>
                  {`${translate('header.applicationName2')}`}
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid className={classes.connectionFormContainer} item>
          <Grid alignItems='center' className={classes.connectionForm} container direction="column" spacing={3}>
            <Grid className={classes.hello} item>
              {translate('connection.hello')}
            </Grid>
            <Grid item>
              {translate('connection.description')}
            </Grid>
            <Grid item>
              <TextField
                className={classes.connectionInput}
                fullWidth
                label={translate('connection.login')}
                onChange={e => setLogin(e.target.value)}
                value={login}
                variant='outlined'
              />
            </Grid>
            <Grid item>
              <TextField
                className={classes.connectionInput}
                fullWidth
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        edge="end"
                        onClick={() => setShowPassword(prev => !prev)}
                      >
                        {showPassword ?
                          <FontAwesomeIcon icon={faEye}/>
                          :

                          <FontAwesomeIcon icon={faEyeSlash}/>
                        }
                      </IconButton>
                    </InputAdornment>
                  )
                }}
                label={translate('connection.password')}
                onChange={e => setPassword(e.target.value)}
                type={showPassword ? 'text' : 'password'}
                value={password}
                variant='outlined'
              />
            </Grid>
            <Grid item>
              <Button
                className={classes.connectionButton}
                onClick={handleOnConnect}
                variant='outlined'
              >
                {translate('connection.connect')}
              </Button>
            </Grid>
            <Grid className={classes.iconContainer} item>
              <Grid alignItems="center" container justifyContent="center" spacing={3}>
                <Grid item xs={3}>
                  <Grid alignItems="center" container direction="column" spacing={2}>
                    <Grid item>
                      <FontAwesomeIcon color={theme.palette.secondary.main} icon={faArrowsToCircle} size="3x"/>
                    </Grid>
                    <Grid item>
                      {translate('connection.centralization')}
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={3}>
                  <Grid alignItems="center" container direction="column" spacing={2}>
                    <Grid item>
                      <FontAwesomeIcon color={theme.palette.secondary.main} icon={faCheckToSlot} size="3x"/>
                    </Grid>
                    <Grid item>
                      {translate('connection.management')}
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={3}>
                  <Grid alignItems="center" container direction="column" spacing={2}>
                    <Grid item>
                      <FontAwesomeIcon color={theme.palette.secondary.main} icon={faUsers} size="3x"/>
                    </Grid>
                    <Grid item>
                      {translate('connection.datafari')}
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </>)
  )
}