
const getCookie = name => {
  const allCookies = document.cookie.split(';');

  if (allCookies.length > 0) {
    const cookie = allCookies.find(c => c.trim().startsWith(name));

    if (cookie) {
      // We get a cookie like that :
      // refreshToken=peHjJxWhrw5xN9QIMP32SvDhurOgsYax9ZX0zwc/aeE=
      // so we get the string from the (name.length) + (1 for the '=')
      return cookie.trim().substring(name.length + 1);
    }

    return null;
  }

  return null;
};

const setCookie = (name, value, expires, maxAge = 3600) => {
  if (!expires) {
    document.cookie = `${name}=${value}; max-age=${maxAge}${
      window.location.host.includes('localhost') ? 'domain=localhost; path=/' : `; secure; sameSite=None; domain=${document.domain}`}`;
    return;
  }

  document.cookie = `${name}=${value}; expires=${expires}${
    window.location.host.includes('localhost') ? 'domain=localhost; path=/' : `; secure; sameSite=None; domain=${document.domain}`}`;
};

const deleteCookie = name => {
  document.cookie = `${name}=; expires=${new Date('1970-01-01').toUTCString()}${
    window.location.host.includes('localhost') ? 'domain=localhost; path=/' : `; secure; sameSite=None; domain=${document.domain}`}`;
};

export const CookieHelper = {
  getCookie,
  setCookie,
  deleteCookie
};