import { API_URL } from "utils/constants/app";
import { RequestHelper } from "utils/helpers/RequestHelper";

const cvServiceUrl = `${API_URL}/cv`;

const deleteCV = cvInfos => RequestHelper.POST(`${cvServiceUrl}/delete`, cvInfos);

const addCV = (consultantRecid, formData) => RequestHelper.POST_FORMDATA(`${cvServiceUrl}/add/${consultantRecid}`, formData);

export const CVService = {
  deleteCV,
  addCV
}