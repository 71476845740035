import React from 'react';
import { Tooltip } from '@material-ui/core';
import * as PropTypes from 'prop-types';
import { translate } from 'utils';

export const CustomTooltip = ({ children, title }) => (
  <Tooltip arrow enterDelay={500} leaveDelay={200} title={typeof title === 'string' ? translate(title) : title}>
    {children}
  </Tooltip>
);

CustomTooltip.propTypes = {
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.object]).isRequired
};