import { API_URL } from "utils/constants/app";
import { RequestHelper } from "utils/helpers/RequestHelper";

const skillServiceUrl = `${API_URL}/skill`;

const getCategories = () => RequestHelper.GET(`${skillServiceUrl}/categories`);

const getSubcategoriesByCategoryId = categoryId => RequestHelper.GET(`${skillServiceUrl}/subcategories/${categoryId}`);

const getSkillsBySubcategoryId = (categoryId, subcategoryId) => RequestHelper.GET(`${skillServiceUrl}/skills/${categoryId}/${subcategoryId}`);

export const SkillService = {
  getCategories,
  getSubcategoriesByCategoryId,
  getSkillsBySubcategoryId
}