import { createTheme, responsiveFontSizes } from "@material-ui/core";

const THEME = responsiveFontSizes(createTheme({
  palette: {
    primary: {
      main: '#638B97',
      light: '#D4F6FF'
    },
    secondary: {
      main: '#F57A67',
      light: '#FFE0CD'
    },
    warning: {
      main: '#FBA84D',
      dark: '#ce7b21'
    },
    error: {
      main: '#FF7373',
      dark: '#c73b3b'
    },
    success: {
      main: '#26C095',
      dark: '#138a69'
    },
    text: {
      primary: '#070D13',
      secondary: 'rgba(0, 0, 0, 0.66)',
      disabled: '#737373',
      disabledLight: '#E6EAED',
      disabledDark: '#535353',
      modified: '#31A597',
      modifiedLight: '#BCE1DC'
    },
    divider: '#DFE4ED',
    background: {
      default: '#F7FAFC',
      paper: '#FFFFFF',
      disabled: '#D9D9D9',
      footer: "#E8F0F4"
    },
    shadow: {
      card: '0 1px 10px 0 rgba(162,189,221,0.35)',
      hover: '0 2px 20px 0 rgba(162,189,221,0.35)',
      footer: '0 5px 20px 0 rgba(122,149,199,0.6)',
      roundIcon: '0px 5px 5px rgba(122,149,199,0.6)'
    },
    checkbox: {
      main:'#D9D9D9'
    }
  },
  typography: {
    fontFamily: 'Poppins, sans-serif',
    fontSize: 15
  },
  breakpoints: {
    values: {
      xs: 0,
      sm: 750,
      md: 1020,
      lg: 1280,
      xl: 1920
    }
  }
}));

THEME.typography.body1 = {
  fontSize: '1rem',
  [THEME.breakpoints.down('xs')]: {
    fontSize: '0.9rem'
  }
};

export default THEME;