import i18n from 'i18next';

export const translate = (key, options) => {
  if (i18n.exists(key)) {
    return i18n.t(key, options);
  }

  if (key && key.indexOf('.') > -1) {
    return key.split('.')[1];
  }

  return key;
};