import { API_URL } from "utils/constants/app";
import { RequestHelper } from "utils/helpers/RequestHelper";

const userServiceUrl = `${API_URL}/user`;

const getUserInfo = () => RequestHelper.GET(`${userServiceUrl}/userInfo`);

const changePassword = data => RequestHelper.POST(`${userServiceUrl}/changePassword`, data);

const postBugReport = data => RequestHelper.POST(`${userServiceUrl}/bugreport`, data);

export const UserService = {
  getUserInfo,
  changePassword,
  postBugReport
}