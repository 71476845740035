/* eslint-disable react-hooks/exhaustive-deps */
import React, {useEffect, useState } from 'react';
import { CircularProgress, Grid } from '@material-ui/core';
import SectionTitle from 'commons/SectionTitle/SectionTitle';
import { SkillService } from 'services/SkillService';
import ConsultantCategory from './ConsultantCategory';
import {translate} from "../../../utils";
import {useSnackbar} from "notistack";
import { Skeleton } from '@material-ui/lab';



const ConsultantSkillsList = ({setSkillsTreeStructure, skillsTreeStructure, isLoadingSkills, isCreation}) => {
  const [isLoading, setIsLoading] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const [lastNewSubcategoryRecid, setLastNewSubcategoryRecid] = useState(-1);

  useEffect(() => {
    SkillService.getCategories().then(resp => {
      if(!isLoadingSkills && isCreation){
        setSkillsTreeStructure([...resp]);
      }
      setIsLoading(false);
    }).catch(() => {
      enqueueSnackbar(translate('consultantSkill.error'), {variant: 'error'});
      setIsLoading(false);
    })
  }, [isLoadingSkills])

  return (
    (isLoading || skillsTreeStructure === null) ?
      <CircularProgress/>
      :
      skillsTreeStructure.map(category => (
        <Grid container direction="column" spacing={2}>
          <SectionTitle title={category.name}/>
          {isLoadingSkills ?
            <Skeleton height="100px" variant="text" width="100%" />
            :
            <ConsultantCategory
              category={category}
              lastNewSubcategoryRecid={lastNewSubcategoryRecid}
              setLastNewSubcategoryRecid={setLastNewSubcategoryRecid}
              setSkillsTreeStructure={setSkillsTreeStructure}
              skillsTreeStructure={skillsTreeStructure}
            />
          }
        </Grid>
      ))

  )
};

export default ConsultantSkillsList;