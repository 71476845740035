import { API_URL } from "utils/constants/app";
import { RequestHelper } from "utils/helpers/RequestHelper";

const consultantServiceUrl = `${API_URL}/consultant`;

const getConsultants = (filter, signal) => RequestHelper.POST(`${consultantServiceUrl}/list`, filter, {signal});

const getConsultantDetail = consultantId => RequestHelper.GET(`${consultantServiceUrl}/${consultantId}`);

const getTypologies = () => RequestHelper.GET(`${consultantServiceUrl}/typologies`);

const getCivilities = () => RequestHelper.GET(`${consultantServiceUrl}/civilities`);

const getFrenchAreas = () => RequestHelper.GET(`${consultantServiceUrl}/frenchAreas`);

const getCountries = () => RequestHelper.GET(`${consultantServiceUrl}/countries`);

const getConsultantSkills = consultantId => RequestHelper.GET(`${consultantServiceUrl}/${consultantId}/skills`);

const deleteConsultants = (consultantRecids, signal) => RequestHelper.POST(`${consultantServiceUrl}/delete`, consultantRecids, {signal});

const saveConsultant = (consultantId, data) => RequestHelper.POST(`${consultantServiceUrl}/${consultantId}/save`, data);

export const ConsultantService = {
  getConsultants,
  getConsultantDetail,
  getTypologies,
  getCivilities,
  getFrenchAreas,
  getCountries,
  getConsultantSkills,
  deleteConsultants,
  saveConsultant
}