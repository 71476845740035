/* eslint-disable react-hooks/exhaustive-deps */
import React, {useEffect} from "react";
import {SnackbarProvider} from 'notistack';
import Header from "components/Header/Header";
import {InnerRoutes} from "routes/InnerRoutes";
import THEME from "./theme";
import {DialogProvider} from "hook/DialogProvider";
import {ErrorBoundary} from "./ErrorBoundary";
import {ConnectionPage, ErrorPage} from "pages";
import {ROUTES} from "utils/constants/routes";
import {BrowserRouter, Navigate, Route, Routes, useLocation} from 'react-router-dom'
import {UserInfoProvider, useUserInfo} from "hook/UseUserInfo";
import {CssBaseline, ThemeProvider} from "@material-ui/core";
import {UserService} from "services";
import { CookieHelper } from "utils/helpers/CookieHelper";

const MainApp = () => {
  const {userInfos, connectUser, disconnectUser} = useUserInfo();
  const location = useLocation();

  useEffect(() => {
    if (CookieHelper.getCookie('token') && !userInfos) {
      UserService.getUserInfo().then(userInfo => {
        const userConnected = {
          firstname: userInfo.firstname,
          hasChangedPassword: userInfo.hasChangedPassword,
          id: userInfo.id,
          lastname: userInfo.lastname,
          status: userInfo.isActive,
          token: userInfo.token
        }
        connectUser(userConnected);
      }).catch(() => {
        disconnectUser(ROUTES.CONNECTION);
      });
    }
  }, [])

  if (!CookieHelper.getCookie('token') && !userInfos) {
    return <Navigate replace to={ROUTES.CONNECTION}/>
  } else {
    if (userInfos !== null && !userInfos.hasChangedPassword && !ROUTES.USER.endsWith(location.pathname)) {
      return <Navigate replace to={ROUTES.USER}/>
    } else {
      return (<>
        <Header/>
        <InnerRoutes/>
      </>)
    }
  }
};

const App = () => {
  return (
    <ThemeProvider theme={(THEME)}>
      <CssBaseline>
        <BrowserRouter>
          <DialogProvider>
            <UserInfoProvider>
              <SnackbarProvider autoHideDuration={10000} maxSnack={4}>
                <ErrorBoundary>
                  <Routes>
                    <Route element={<ConnectionPage/>} exact path={ROUTES.CONNECTION}/>
                    <Route element={<ErrorPage/>} path={ROUTES.ERROR}/>
                    <Route element={<MainApp/>} path={ROUTES.BASE}/>
                  </Routes>
                </ErrorBoundary>
              </SnackbarProvider>
            </UserInfoProvider>
          </DialogProvider>
        </BrowserRouter>
      </CssBaseline>
    </ThemeProvider>
  );
};
export default App;