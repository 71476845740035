/* eslint-disable react/forbid-prop-types,react-hooks/exhaustive-deps */
import React, { useEffect } from 'react';
import {
  CircularProgress,
  Table, TableBody, TableCell, TableHead, TableRow
} from '@material-ui/core';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import { MOBILE_BREAKPOINT } from 'utils/constants/responsive';
import CustomIcon from 'commons/Icon/CustomIcon';
import { faCircleInfo } from '@fortawesome/pro-solid-svg-icons';
import { translate } from 'utils';

const CELL_STYLE = { maxWidth: '30vw', overflowX: 'overlay', overflowY: 'hidden' };

const useStyles = makeStyles((theme) => ({
  hoverRow: {
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: theme.palette.primary.tableHover
    }
  },
  rowStriped: {
    '&:nth-child(even)': {
      backgroundColor: theme.palette.text.disabledLight
    },
    '& > td': {
      [theme.breakpoints.down('sm')]: {
        padding: '2px 10px 2px 10px'
      }
    }
  },
  tableHeader: {
    ...CELL_STYLE,
    fontWeight: 'bold',
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.common.white,
    textTransform: 'uppercase',
    [theme.breakpoints.down('sm')]: {
      padding: '3px 12px 3px 12px'
    },
    [theme.breakpoints.down(MOBILE_BREAKPOINT)]: {
      padding: '1px 8px 1px 8px'
    }
  },
  tableCell: {
    ...CELL_STYLE
  },
  overflowHidden: {
    overflow: 'hidden'
  },
  bold: {
    fontWeight: 'bold'
  }
}));

export const ConsultantsTable = ({
  rows, headers, onRowCLick, stickyHeader, isLoading, onChange, total
}) => {
  const classes = useStyles();
  const theme = useTheme();

  useEffect(() => {
    onChange();
  }, [total]);

  return (
    <>
      <Table
        className={classes.white}
        size="small"
        stickyHeader={stickyHeader}
      >
        <TableHead>
          <TableRow>
            {headers.map((header) => (
              <TableCell
                align={header.align}
                className={classes.tableHeader}
                key={header.name}
                width={header.width}
              >
                {header.label && translate(header.label)}
                {header.displayInfo &&
                     <CustomIcon
                       color={theme.palette.primary.main}
                       icon={faCircleInfo}
                       size="lg"
                       style={{marginLeft: '5px'}}
                       tooltip={header.infoText}
                     />
                }
                {header.component && header.component}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.length === 0
            ? (
              !isLoading &&
              <TableRow>
                <TableCell align='center' colSpan={headers.length}>
                  {translate('table.noDataInTable')}
                </TableCell>
              </TableRow>
            )
            : rows.map((row) => (
              <TableRow
                className={`${classes.rowStriped} ${onRowCLick ? classes.hoverRow : ''}`}
                key={row.id}
                onClick={() => onRowCLick && onRowCLick(row)}
              >
                {headers.map((column) => (
                  <TableCell
                    align={column.align}
                    className={`${classes.tableCell} ${column.bold ? classes.bold : ''}`}
                    key={column.name}
                    width={column.width}
                  >
                    {column.template(row)}
                  </TableCell>
                ))}
              </TableRow>
            ))}
        </TableBody>
        {isLoading &&
          <TableRow>
            {[...Array(Math.floor(headers.length/2)-1).keys()].map((keyCol) => (
              <td className={classes.cell} key={keyCol}>
              </td>
            ))}
            <TableCell align='center'>
              <CircularProgress />
            </TableCell>
            {[...Array(Math.floor(headers.length/2)-1).keys()].map((keyCol) => (
              <td className={classes.cell} key={keyCol}>
              </td>
            ))}
          </TableRow>
        }
      </Table>
    </>
  );
};

ConsultantsTable.propTypes = {
  filters: PropTypes.object,
  headers: PropTypes.arrayOf(PropTypes.shape({
    isOnMobile: PropTypes.bool,
    isSortable: PropTypes.bool,
    label: PropTypes.string,
    name: PropTypes.string,
    sortOn: PropTypes.string,
    template: PropTypes.func
  })).isRequired,
  isLoading: PropTypes.bool,
  onChange: PropTypes.func,
  onRowCLick: PropTypes.func,
  rowKey: PropTypes.string,
  rows: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  stickyHeader: PropTypes.bool,
  total: PropTypes.number
};

ConsultantsTable.defaultProps = {
  filters: {},
  isLoading: false,
  onChange: () => {},
  onRowCLick: null,
  rowKey: null,
  stickyHeader: false,
  total: null
};