/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { SkillService } from 'services/SkillService';
import { AutoCompleteWithValue } from 'commons/AutoComplete/AutoCompleteWithValue';
import { updateSkills } from 'utils/helpers/SkillHelper';



const ConsultantSubcategory = ({
  category, subcategory, setSkillsTreeStructure, skillsTreeStructure
}) => {
  const [skills, setSkills] = useState([]);
  const [isLoadingSkills, setIsLoadingSkills] = useState(true);

  useEffect(() => {
    SkillService.getSkillsBySubcategoryId(category.recid, subcategory.recid).then(resp => {
      const skillsBeforeSort = [...resp];
      const skillsIdSelected = skillsTreeStructure.filter(cat => cat.recid === category.recid)[0]?.skills?.filter(
        subcat => subcat.recid === subcategory.recid)[0].skills?.filter(
        skill => skill.selected).map(skill => skill.recid);
      skillsBeforeSort.forEach(skill => {
        if (skillsIdSelected?.includes(skill.recid)) {
          skill.selected = true;
        }
      })
      setSkills([...skillsBeforeSort]);
      setIsLoadingSkills(false);
    })
  }, [])

  const handleSkillsPopulate = () => {
    const skillsTreeStructureTmp = [...skillsTreeStructure];
    const categoryIndex = skillsTreeStructureTmp.findIndex(s => s.recid === category.recid);
    if(skillsTreeStructureTmp[categoryIndex]) {
      const subcategoryIndex = skillsTreeStructureTmp[categoryIndex].skills.findIndex(s => s.recid === subcategory.recid);
      if (subcategoryIndex !== -1) {
        if (!skillsTreeStructureTmp[categoryIndex].skills[subcategoryIndex].skills
          || skillsTreeStructureTmp[categoryIndex].skills[subcategoryIndex].skills.length === 0) {
          skillsTreeStructureTmp[categoryIndex].skills[subcategoryIndex].skills = [...skills];//skills.filter(skill => skill.selected);
        } else {
          skillsTreeStructureTmp[categoryIndex].skills[subcategoryIndex].skills = updateSkills(
            skillsTreeStructureTmp[categoryIndex].skills[subcategoryIndex].skills,
            // skills.filter(skill => skill.selected)
            skills
          );
        }
      }
    }
    setSkillsTreeStructure(skillsTreeStructureTmp);
    setIsLoadingSkills(false);
  }

  useEffect(() => {
    if(!isLoadingSkills)
      handleSkillsPopulate();
  }, [skills, isLoadingSkills])

  const  selectedSkillsGroupByExistingId = selectedSkills => {
    const selectedSkillsWithId = [];
    const selectedSkillsWithoutId = [];
    selectedSkills.forEach(selectedSubcategory => {
      if (selectedSubcategory.recid){
        selectedSkillsWithId.push(selectedSubcategory);
      }else{
        selectedSkillsWithoutId.push(selectedSubcategory);
      }
    })
    return {selectedSkillsWithId, selectedSkillsWithoutId};
  }

  const handleSelectSkills = selectedSkills => {
    const {selectedSkillsWithId, selectedSkillsWithoutId} = selectedSkillsGroupByExistingId(selectedSkills);
    const selectedSkillsId = selectedSkillsWithId.map(selectedSubcategory => selectedSubcategory.recid)
    const skillsTmp = [...skills];
    skillsTmp.forEach((subcategoryTmp, index) => {
      if(selectedSkillsId.includes(subcategoryTmp.recid)){
        skillsTmp.splice(index, 1, {...skillsTmp[index], selected: true});
      }else{
        skillsTmp.splice(index, 1, {...skillsTmp[index], selected: false});
      }
    })

    selectedSkillsWithoutId.forEach(selectedSkillWithoutId => {
      skillsTmp.push({
        recid: null,
        categoryId: category.recid,
        subcategoryId: subcategory.recid,
        origin: 'CUSTOM',
        name: selectedSkillWithoutId.name ?? selectedSkillWithoutId,
        selected: true
      });
    })
    setSkills(skillsTmp?.filter(skillTmp => skillTmp.recid || (!skillTmp.recid && skillTmp.selected)));
  }

  return (
    subcategory.skills ?
      <AutoCompleteWithValue
        isFree
        isLoading={isLoadingSkills}
        isMultiple
        label={subcategory.name}
        onChange={selectedSkills => handleSelectSkills(selectedSkills)}
        options={subcategory.skills}
        value={skillsTreeStructure.filter(cat => cat.recid === category.recid)[0]?.skills?.filter(
          subcat => subcat.recid === subcategory.recid)[0]?.skills?.filter(skill => skill.selected)
        }
        variant="outlined"
      />
      : <></>
  )
};

export default ConsultantSubcategory;