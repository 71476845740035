import React from 'react';
import {Grid, makeStyles, TextField} from "@material-ui/core";
import SectionTitle from "commons/SectionTitle/SectionTitle";
import {translate} from "utils";
import {useUserInfo} from "hook/UseUserInfo";

const useStyles = makeStyles((theme) => ({
  passwordError: {
    backgroundColor: theme.palette.error.dark,
    color: theme.palette.common.white
  },
  root: {
    '&$error': {
      color: theme.palette.error.dark
    }
  },
  asterisk: {
    '&$error': {
      color: theme.palette.error.dark
    }
  },
  underline: {
    '&$error:after': {
      borderBottomColor: theme.palette.error.dark
    }
  },
  error: {}
}));

export const PasswordChangeForm = ({handleChangeUser, updatePasswordView}) => {

  const passwordPattern =
  new RegExp(
    "^(?=.*[a-z])(?=.*[A-Z])(?=.*\\d)(?=.*[@#$!%*?&§/;,:µ¤\\}=+)\\]°_\\-|\\(\\['{“~])[A-Za-z\\d@#$!%*?&§/;,:µ¤\\}=+)\\]°_\\-|\\(\\['{“~]{8,}$"
  );
  const classes = useStyles();
  const {userInfos} = useUserInfo();

  return (
    <Grid container direction="column">
      <SectionTitle title="passwordChangeForm.title"/>
      {!userInfos?.hasChangedPassword && (
        <Grid className={`${classes.passwordError}`} item>
          {translate("passwordChangeForm.changePasswordFirstConnexion")}
        </Grid>
      )}
      <Grid container direction="column" spacing={3}>
        <Grid item>
          <Grid container direction="column" spacing={2}>
            <Grid item xs={4}>
              <TextField
                error={updatePasswordView?.oldPassword &&
                  updatePasswordView?.newPassword &&
                  updatePasswordView?.oldPassword === updatePasswordView?.newPassword}
                FormHelperTextProps={{
                  classes: {
                    root: classes.root,
                    error: classes.error
                  }
                }}
                helperText={updatePasswordView?.oldPassword &&
                  updatePasswordView?.newPassword &&
                  updatePasswordView?.oldPassword === updatePasswordView?.newPassword
                  ? translate("passwordErrors.newPasswordMustBeDifferentThanPreviousOne") : ""}
                label={translate("userPage.oldPassword")}
                onBlur={e => handleChangeUser("oldPassword", e.target.value)}
                onChange={e => handleChangeUser("oldPassword", e.target.value)}
                shrink={updatePasswordView?.oldPassword !== '' ?? false}
                style={{width: '100%'}}
                type="password"
                value={updatePasswordView?.oldPassword ?? ''}
              />
            </Grid>
            <Grid item xs={4}>
              <TextField
                error={updatePasswordView?.newPassword && !passwordPattern.test(updatePasswordView?.newPassword)}
                FormHelperTextProps={{
                  classes: {
                    root: classes.root,
                    error: classes.error
                  }
                }}
                helperText={updatePasswordView?.newPassword && !passwordPattern.test(updatePasswordView?.newPassword)
                  ? translate("passwordErrors.newPasswordNotSecuredEnough") : ""}
                InputLabelProps={{
                  classes: {
                    root: classes.root,
                    asterisk: classes.asterisk,
                    error: classes.error
                  }
                }}
                InputProps={{
                  classes: {
                    root: classes.root,
                    error: classes.error,
                    underline: classes.underline
                  }
                }}
                label={translate("userPage.newPassword")}
                onBlur={e => handleChangeUser("newPassword", e.target.value)}
                onChange={e => handleChangeUser("newPassword", e.target.value)}
                shrink={updatePasswordView?.newPassword !== '' ?? false}
                style={{width: '100%'}}
                type="password"
                value={updatePasswordView?.newPassword ?? ''}
              />
            </Grid>
            <Grid item xs={4}>
              <TextField
                error={updatePasswordView?.newPasswordRepeat && updatePasswordView?.newPasswordRepeat !== updatePasswordView.newPassword}
                FormHelperTextProps={{
                  classes: {
                    root: classes.root,
                    error: classes.error
                  }
                }}
                helperText={updatePasswordView?.newPasswordRepeat && updatePasswordView?.newPasswordRepeat !== updatePasswordView.newPassword
                  ? translate("passwordErrors.newPasswordsNotMatching") : ""}
                InputLabelProps={{
                  classes: {
                    root: classes.root,
                    asterisk: classes.asterisk,
                    error: classes.error
                  }
                }}
                InputProps={{
                  classes: {
                    root: classes.root,
                    error: classes.error,
                    underline: classes.underline
                  }
                }}
                label={translate("userPage.newPasswordRepeat")}
                onBlur={e => handleChangeUser("newPasswordRepeat", e.target.value)}
                onChange={e => handleChangeUser("newPasswordRepeat", e.target.value)}
                shrink={updatePasswordView?.newPasswordRepeat !== '' ?? false}
                style={{width: '100%'}}
                type="password"
                value={updatePasswordView?.newPasswordRepeat ?? ''}
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  )
};