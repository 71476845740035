import React from 'react';
import { Route, Routes } from 'react-router-dom';
import { ROUTES } from 'utils/constants/routes';
import { ConsultantDetailPage, ConsultantsPage, DatafariPage, UserPage } from 'pages';

export const InnerRoutes = () => {
  return (
    <Routes>
      <Route element={<ConsultantsPage/>} exact path={ROUTES.CONSULTANTS} />
      <Route element={<DatafariPage/>} exact path={ROUTES.DATAFARI} />
      <Route element={<UserPage/>} exact path={ROUTES.USER} />
      <Route element={<ConsultantDetailPage/>} path={ROUTES.CONSULTANT} />
    </Routes>
  );
};