/* eslint-disable no-console */
import { generatePath, matchPath } from 'react-router-dom';
import { ROOT_PATH } from './app';

export const ROUTES = {
  ERROR: `${ROOT_PATH}error`,
  CONNECTION: `${ROOT_PATH}connection`,
  HOME: `${ROOT_PATH}home`,
  BASE: `*`,
  CONSULTANTS: `${ROOT_PATH}consultants`,
  DATAFARI: `${ROOT_PATH}datafari`,
  USER:`${ROOT_PATH}user`,
  CONSULTANT: `${ROOT_PATH}consultant/:consultantId`
};

export const ROUTE_MATCHES = (location, route) =>  matchPath({ path: route, exact: false },location.pathname);

export const DEFAULT_CONSULTANT_ID_ROUTE = '-1';

export const GENERATE_ROUTE = (route, consultantId = DEFAULT_CONSULTANT_ID_ROUTE) => {
  return generatePath(route,
    {consultantId}
  );
};