import * as React from 'react';
import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, makeStyles } from '@material-ui/core';
import * as PropTypes from 'prop-types';
import { translate } from 'utils';
import { CustomButton } from '../Button/CustomButton';

export const DialogType = {
  CONFIRMATION: 'confirmation',
  INFO: 'info',
  ERROR: 'error'
};

const useStyles = makeStyles((theme) => ({
  error: {
    backgroundColor: theme.palette.error.main,
    color: theme.palette.common.white
  },
  content: {
    minWidth: 300
  }
}));

export const CustomDialog = ({
  open,
  title,
  variant,
  description,
  onSubmit,
  onClose
}) => {
  const classes = useStyles();
  return (
    <Dialog onClose={onClose} open={open}>
      <DialogTitle className={variant === DialogType.ERROR ? classes.error : ''}>
        {translate(title)}
      </DialogTitle>
      <DialogContent className={classes.content}>
        <DialogContentText>
          {translate(description)}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        {variant === DialogType.CONFIRMATION && (
          <>
            <CustomButton onClick={onClose} variant="primaryLight">
              {translate("popup.cancel")}
            </CustomButton>
            {/* eslint-disable-next-line jsx-a11y/no-autofocus */}
            <CustomButton autoFocus onClick={onSubmit}>
              {translate("popup.agree")}
            </CustomButton>
          </>
        )}

        {variant === DialogType.INFO && (
          <CustomButton onClick={onClose}>
            ok
          </CustomButton>
        )}

        {variant === DialogType.ERROR && (
          <CustomButton onClick={onClose} variant="error">
            ok
          </CustomButton>
        )}
      </DialogActions>
    </Dialog>
  );
};


CustomDialog.propTypes = {
  description: PropTypes.string,
  onClose: PropTypes.func,
  onSubmit: PropTypes.func,
  open: PropTypes.bool,
  title: PropTypes.string,
  variant: PropTypes.string
};

CustomDialog.defaultProps = {
  description: '',
  onClose: () => {},
  onSubmit: () => {},
  open: false,
  title: '',
  variant: DialogType.INFO
};